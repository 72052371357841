<template lang="pug">
<!-- 2023周年慶增加區塊 -->
.gift-content-anniversary
  b-container
    b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col
        .text-center.description-text
          h3(style='margin-bottom: 5px;') 主委特別加碼
          h1 🌟幸運抽獎許願池🌟
          p
            | 除了原本的「好禮第1重」獎項照舊抽出
            br.pc-br
            | 於2022/12/9 ~ 2022/1/18活動期間
            br.pc-br
            | 推薦滿
            span.highlight 5張
            | 抽獎券，立即擁有下述獎池資格
            br.pc-br
            span.font-weight-bold 週年慶「主委加碼特別獎」
            br.pc-br
            span.highlight iPhone14、Switch、Apple Watch SE
            br.pc-br
            | 將會依據推薦人數上升，累計抽出獎項！
          p.mb-0 只要親友透過
            span.highlight 你的專屬推薦頁面
            | 預約試聽，
            br.pc-br
            | 即可獲得抽獎券！
            br.pc-br
            | 累積越多抽獎券，不但獎項越
            span.highlight 豐富
            br.pc-br
            | 中獎機率也越高噢！
    h3.text-center.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true') 如何得到抽獎券？
    b-row.row-margin
      b-col(data-aos='fade-up-right' data-aos-duration='1000' data-aos-once='true' cols='12' md='6')
        b-row
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-two.png' alt='')
              p
                | 推薦第1位
                br
                | 獲得2張抽獎券
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png' alt='')
              p
                | 推薦第2~9位
                br
                | 獲得1張抽獎券
        b-row
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-three.png' alt='')
              p
                | 推薦第10位
                br
                | 獲得3張抽獎券
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png' alt='')
              p
                | 推薦第11位以上
                br
                | 獲得1張抽獎券
      b-col(data-aos='fade-up-left' data-aos-duration='1000' data-aos-once='true' cols='12' md='6')
        .ticket-caption
          h4
            span.highlight 推薦越多，得獎機率越高！
          p
            | ◆ 推薦第1位朋友，
            br.m-br
            | 獲得2張抽獎券
          p
            | ◆ 推薦第2-9位朋友，
            br.m-br
            | 獲得1張抽獎券
          p.ps-text Ps. 當推薦第4位朋友，總共累計獲得5張抽獎券就能擁有參加「主委加碼獎池」的資格囉
          p
            | ◆ 推薦第10位朋友，
            br.m-br
            | 獲得3張抽獎券
          p
            | ◆ 推薦第11位(含)以上朋友，
            br.m-br
            | 各將獲得1張抽獎券
          p.text-center.my-4 例：小wu在同一個月推薦了13位朋友，所以小wu可以在當月拿到16張抽獎券呦！
    h3.text-center.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true') 抽獎許願池怎麼玩？
    b-row.row-margin
      b-col.text-center(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true' cols='12' md='12')
        p 根據WUWOW在
          span.highlight 2022/12/9-2023/1/18 累計發送給全體學員的抽獎券數量
          | ，抽出相對應的獎項，
          br.m-br1
          | 累積越多，好禮越大，快點樓頂揪樓下，旁邊揪旁邊，一起WU朋引伴作伙來吧！
      b-col(v-for='(img,index) in giftImgGroup' :key='img.role_no' data-aos='fade-up' data-aos-duration='1000' data-aos-delay='500' data-aos-once='true' cols='6' md='4')
        .imgBox(:class="(index % 2)==0?'color_1':'color_2'")
          img.img-fluid(:src='img.uri_path')
        p.gift(v-html='img.prize_name')
    b-row.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true')
      b-col(cols='12' md='12')
        .rule-caption
          h4.text-center
            span.highlight 推薦越多，得獎機率越高！
          p ◆ 當月WUWOW Jr 發送滿70張抽獎券，將加抽出 Apple Watch SE
          p ◆ 當月WUWOW Jr 發送滿150張抽獎券，將加抽出 Apple Watch SE + Switch
          p ◆ 當月WUWOW Jr 發送滿200張抽獎券，將加抽出 Apple Watch SE + Switch + iPhone 14
    .example-text.text-center(data-aos='fade-up' data-aos-duration='1500' data-aos-delay='500' data-aos-once='true')
      img.switch-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-switch.png' alt='')
      img.iphone-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-iphone-watch.png' alt='')
      | 例：當月 WUWOW Jr 發送給全體學員200張抽獎券，
      br
      | 那麼在這個月將會抽出Apple Watch SE + Switch + iPhone14 共三位幸運兒喲！
    .rightNowBox.anniversary-right-now-box.text-center.pb-0(data-aos='fade-up' data-aos-duration='1500' data-aos-delay='500' data-aos-once='true')
      h4 你還在等什麼？
      #rightNowBtn2.rightNowBtn(data-aos='zoom-in' data-aos-duration='500' data-aos-delay='1000' data-aos-once='true')
        a(:href='mgmRecommendUrl' target='_blank')
          span 立刻開揪
      p 快與朋友一起體驗最有效的線上英文學習囉！
      .shareContent.w-100.px-4.p
        customizedsharetext(:usePage="'giftContentAnniversary'")
</template>

<script type="text/javascript">
import customizedsharetext from '../customizedShareText';

export default {
  components: {
    customizedsharetext,
  },
  props: {
    mgmRecommendUrl: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
      giftImgGroup: [
        { 'prize_name': 'Apple Watch', 'uri_path': 'https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-award-apple-watch.png ', 'rule_no': 1 },
        { 'prize_name': 'Switch (顏色隨機)', 'uri_path': 'https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-award-switch.png', 'rule_no': 2 },
        { 'prize_name': 'iPhone14', 'uri_path': 'https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-award-iphone.png', 'rule_no': 3 },
      ],
      firstAward: '',
      secondAward: '',
      thirdAward: '',
      fourthAward: '',
      showShareCustomizationContent: false,
    };
  },
};
</script>
