<template lang="pug">
#gift-step1.gift-content-one
  b-container
    b-row.title-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col
        .text-center
          h3(style='margin-bottom: 5px;') 第一重好禮
          h1 ✨幸運抽獎許願池✨
          h4 （每月開獎）
    b-row.mb-5(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col
        .text-center.row-margin
          p
            | 只要親友透過
            span.highlight 你的專屬推薦頁面
            span
            | 預約試聽
            br.pc-br
            | 即可獲得當月抽獎券！累積越多抽獎券
            br.pc-br
            | 不但獎項越豪華，中獎機率也越高噢！
            br
            br.pc-br
            | （當月抽獎卷當月抽出，不會累積到隔月噢！）
    .childrenDay(v-if="deadlinesAfter")
      h2
        | 歡慶
        span 兒童節
        | 加碼啦！
      h3 活動限時 2024/4/1 ~ 2024/4/14
      p
        | 於活動期間內，
        br
        | 推薦好友成功，推薦者可得
        span 《下午茶卷》
        | ！
      img(:src="require('@/assets/childrensday2024/mgm.png')", alt="childrenDay")
      article
        p 備註:
        ul
          li 1. 有效好友名單判定資格如【注意事項】中的「B-5推薦獎」規則。
          li 2. 午茶卷獎勵發放時間為：2024/4/30 前採信件統一寄送獎勵序號。
          li 3. 若因消費者填寫錯誤信箱資訊導致獎勵遺失，則不補發。
    h3.text-center.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      | 如何得到抽獎券？
    b-row.row-margin
      b-col(data-aos='fade-up-right' data-aos-duration='1000' data-aos-once='true' cols='12' md='6')
        b-row
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-two.png' alt='')
              p
                | 推薦第1位
                br
                | 獲得2張抽獎券
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png' alt='')
              p
                | 推薦第2~9位
                br
                | 獲得1張抽獎券
        b-row
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-three.png' alt='')
              p
                | 推薦第10位
                br
                | 獲得3張抽獎券
          b-col(cols='6')
            .imgBox.color_1.ticket
              img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png' alt='')
              p
                | 推薦第11位以上
                br
                | 獲得1張抽獎券
      b-col(data-aos='fade-up-left' data-aos-duration='1000' data-aos-once='true' cols='12' md='6')
        .ticket-caption
          h4.mb-3
            span.highlight 推薦越多，得獎機率越高！
          p
            | 推薦第1位朋友，
            br.m-br
            | 獲得2張抽獎券
          p
            | 推薦第2-9位朋友，
            br.m-br
            | 獲得1張抽獎券
          p
            | 推薦第10位朋友，
            br.m-br
            | 獲得3張抽獎券
          p
            | 推薦第11位(含)以上朋友，
            br.m-br
            | 各將獲得1張抽獎券
          p.text-center.my-4 例：小wu在同一個月推薦了13位朋友，所以小wu可以在當月拿到16張抽獎券呦！
    h3.text-center.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      | 抽獎許願池怎麼玩？
    b-row.row-margin
      b-col.text-center(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true' cols='12' md='12')
        p
          | 根據WUWOW Jr在
          span.highlight 當月累計發送給全體學員的抽獎券數量
          | ，抽出相對應的獎項，
          br.m-br1
          | 累積越多，好禮越大，快點樓頂揪樓下，旁邊揪旁邊，一起WU朋引伴作伙來吧！
      b-col(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='500' data-aos-once='true' cols='6' md='4' v-for='(img,index) in giftImgGroup' :key='img.role_no')
        .imgBox(:class="(index % 2)==0?'color_1':'color_2'")
          img.img-fluid(:src='img.uri_path')
        p.gift(v-html='img.prize_name')
    b-row.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true')
      b-col(cols='12' md='12')
        .rule-caption
          h4.text-center
            span.highlight 推薦越多，得獎機率越高！
          p ◆ 當月WUWOW Jr發送了1-29張抽獎券，將抽出 {{firstAward}}
          p ◆ 當月WUWOW Jr發送了30-59張抽獎券，將抽出 {{secondAward}}
          p ◆ 當月WUWOW Jr發送了69-99張抽獎券，將抽出 {{thirdAward}}
          p ◆ 當月WUWOW Jr發送了100-249張抽獎券，將抽出 {{fourthAward}}
          p ◆ 當月WUWOW Jr發送了250-499張抽獎券，將抽出 {{fifthAward}}
          p ◆ 當月WUWOW Jr發送了500張(含)以上抽獎券，將抽出 {{sixthAward}}
          p.text-center
            | 例：當月WUWOW Jr發送給全體學員149張抽獎券，
            br.pc-br
            | 那麼在這個月將會抽出{{fourthAward}}喲！
    slot
    b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true')
      b-col
        .rightNowBox.text-center.anniversary-right-now-box.pb-0
          h3 你還在等什麼？
          #rightNowBtn1.rightNowBtn(data-aos='zoom-in' data-aos-duration='500' data-aos-delay='1000' data-aos-once='true')
            a(:href='mgmRecommendUrl' target='_blank')
              span 立刻開揪
          p
            | 心動不如馬上行動，
            //- br
            //- | 快想想身邊有哪些想學英文卻苦無方法的朋友們，
            br
            | 一起來挑戰最大獎 Macbook Pro！
          .shareContent.w-100
            customizedShareText(:usePage="'giftContentAnniversary'")
</template>

<script type="text/javascript">
import LearnRuleModal from '@/components/wushare/learnRuleModal';
import customizedShareText from '@/components/vipMgm/customizedShareText/index.vue';
import moment from 'moment';
export default {
  name: 'GiftContentOne',

  components: {
    LearnRuleModal,
    customizedShareText,
  },

  props: {
    currentAwardList: {
      type: Object,
      default: function() {
        return {};
      },
    },
    mgmRecommendUrl: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
      deadlines: '2024-04-15 00:00:00',
      deadlinesAfter: false,
      giftImgGroup: {},
      firstAward: '',
      secondAward: '',
      thirdAward: '',
      fourthAward: '',
      fifthAward: '',
      sixthAward: '',
    };
  },

  computed: {},

  watch: {},

  created() {
    this.deadlinesAfter = moment(new Date()).isBetween('2024-04-01 09:00:00', this.deadlines);
  },

  mounted(){
    this.setAwardData();
    this.giftImgGroup = this.currentAwardList.raffles;
  },

  methods: {
    setAwardData(){
      this.firstAward = this.currentAwardList.raffles[0].prize_name;
      this.secondAward = this.currentAwardList.raffles[1].prize_name;
      this.thirdAward = this.currentAwardList.raffles[2].prize_name;
      this.fourthAward = this.currentAwardList.raffles[3].prize_name;
      this.fifthAward = this.currentAwardList.raffles[4].prize_name;
      this.sixthAward = this.currentAwardList.raffles[5].prize_name;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}
.childrenDay {
  text-align: center;
  margin: 0 -20px;
  @include md {
    margin: 0;
  }
  h2 {
    position: relative;
    line-height: 42px;
    font-weight: 700;
    color: #151B1E;
    @include rwd-font(24px, 28px, 35px);
    &::after,
      &::before {
        content: '';
        display: inline-block;
        margin-bottom: -14px;
        width: 40px;
        aspect-ratio: 1/1;

        @include md {
          width: 50px;
        }
      }

      &::before {
        background: url('~@/assets/childrensday2024/gift4.png') no-repeat center / contain;

        @include md {
          margin-right: 16px;
        }
      }

      &::after {
        background: url('~@/assets/childrensday2024/gift3.png') no-repeat center / contain;

        @include md {
          margin-left: 16px;
        }
      }
    span {
      color:#FEB044;
    }
  }
  h3 {
    display: inline-block;
    color: white;
    @include rwd-font(14px, 16px, 16px);
    @include rwd-lh(21px, 24px, 24px);
    background: #535353;
    border-radius: 4px;
    padding: 6px 10px;
    margin: 20px auto 12px auto;
  }
  > p {
    @include rwd-font(14px, 16px, 16px);
    @include rwd-lh(21px, 24px, 24px);
    span {
      font-weight: 700;
      font-size: 21px;
      color:#FEB044;
    }
  }
  img {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
  article {
    text-align: left;
    color: #757373;
    background: #FAF2DC;
    border-radius: 10px;
    padding: 16px 10px;
    font-size: 12px;
    line-height: 18px;
    @include md {
      padding: 16px 20px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      padding: 0;
      margin: 0;
      color: #757373;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
