<template lang="pug">
b-row.justify-content-md-center(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true')
  b-col(cols='12')
    h2.title 推薦四步驟
  b-col(v-for='(step, index) in stepGroup' :key='`stepGroup_${index}`' col='' lg='3')
    img.stepImg(fluid='' :src='step.imgUrl' :alt='step.title' :title='step.title')
    p.text-center.step-text(v-html='step.content')
</template>

<script>
export default {

  name: 'RecommendStep',

  data() {
    return {
      stepGroup: [
        {
          title: 'step1',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step1.png',
          content: '<h4>Step1</h4>推薦人取得<br>專屬推薦連結/代碼',
          index: 1,
        },
        {
          title: 'step2',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step2.png',
          content: '<h4>Step2</h4>將專屬推薦連結/代碼<br>分享給好友',
          index: 2,
        },
        {
          title: 'step3',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step3.png',
          content: '<h4>Step3</h4>好友透過專屬推薦<br>線上完成申請',
          index: 3,
        },
        {
          title: 'step4',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step4.png',
          content: '<h4>Step4</h4>與好友一起享好禮',
          index: 4,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #333333;
  padding: 0;
  margin: 0;
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
