<template lang="pug">
div
    b-row.mb-2
      b-col(align-self="start")
        b-carousel(
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="white"
          style="text-shadow: 1px 1px 2px #333;"
        )
          b-carousel-slide( v-if="!deadlinesAfter" :img-src="require('@/assets/mgm/anniversary_2023.svg')" fluid)
          b-carousel-slide(v-if="moment().isBetween('2024-04-01 00:00:00', '2024-04-30 00:00:00')" img-src="https://cdn2.wuwow.tw/arts/wuwow-nuxt3/2024-children-day/mgm-carousel-children-day.svg" fluid)
          a(@click.prevent="scrollToMethodPage")
            b-carousel-slide(:img-src="require('@/assets/mgm/summerCamp-Deal.jpg')" fluid)
          //- router-link(:to="{name:'event-trial'}")
          //-   b-carousel-slide(:img-src="require('@/assets/mgm/MGM_BN.jpg')" fluid)
          //- b-carousel-slide(:img-src="require('@/assets/mgm/summerBannerMgm.jpg')" fluid)
          b-carousel-slide(img-src="https://cdn2.wuwow.tw/pictures/mgms/mgm-banner-cover.jpg" fluid)
          b-carousel-slide(img-src="https://cdn2.wuwow.tw/pictures/mgms/mgm-banner-1-2.jpg" fluid)
          //- b-carousel-slide(img-src="https://cdn2.wuwow.tw/pictures/mgms/mgm-banner-2-2.jpg" fluid)
          b-carousel-slide(:img-src="require('@/assets/mgm/summerCamp-step2.jpg')" fluid)
    br
    b-row
      b-col.leftSideBox(md="3")
        .shareContent.shareContent-left.text-center.pt-5
            b-row
              b-col
                //- h4 {{$t('inviteFriend')}}
                img.anniversary-2022-left-ad.mx-auto(src="https://cdn2.wuwow.tw/pictures/mgms/encode.svg" alt="現在開始邀請好友" title="現在開始邀請好友")
            b-row
              b-col
                img.mx-auto.top-row(src="https://cdn2.wuwow.tw/pictures/mgms/customized-share-arrow.svg" alt="現在開始邀請好友" title="現在開始邀請好友")
            b-row(v-if="vipRecommendInfo.is_buy_google && !deadlinesAfter")
              b-col.mt-4.position-relative
                h2.sidebar-subTitle Google 專屬團報
                a(:href="mgmRecommendGoogleUrl" target='_blank')
                  img.img-fluid(src="https://cdn2.wuwow.tw/pictures/mgms/google-2023.png" alt="現在開始邀請好友" title="現在開始邀請好友")
            b-row
              b-col.my-4.position-relative
                h2.sidebar-subTitle 好友推薦
                a(:href="mgmRecommendUrl" target='_blank')
                  img.img-fluid(v-if="deadlines" src="https://cdn2.wuwow.tw/pictures/mgms/mgm-link-normal.svg" alt="現在開始邀請好友" title="現在開始邀請好友")
                  img.img-fluid(v-else src="https://cdn2.wuwow.tw/pictures/mgms/sidebar-2023-banner.svg" alt="現在開始邀請好友" title="現在開始邀請好友")
                img.star-icon.star-icon-1(src='https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-1.svg' alt='')
                img.star-icon.star-icon-2(src='https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-2.svg' alt='')
                img.star-icon.star-icon-3(src='https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-3.svg' alt='')
                img.star-icon.star-icon-4(src='https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-4.svg' alt='')
                img.star-icon.star-icon-5(src='https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-5.svg' alt='')
                img.star-icon.star-icon-6(src='https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-6.svg' alt='')
            customizedsharetext(:usePage="'leftSideBar'")
            //- b-col.shareBtnGroup.px-0
              #tooltip-target.copyUrl.shareBtn(@click='copyUrl' @mouseout='copiedMS=false')
                b-tooltip(:show='copiedMS' target='tooltip-target' triggers='click') 複製成功!!
                | 複製網址
              .lineShare.shareBtn
                a(:href='vipMediaShareUrl.lineLink' target='_blank')
                  img(src='https://cdn2.wuwow.tw/pictures/socialites/lineIcon.png' alt='line分享' title='line分享')
                  | 分享
              .fbShare.shareBtn
                a(:href='vipMediaShareUrl.fbLink' target='_blank')
                  img(src='https://cdn2.wuwow.tw/pictures/socialites/fb-icon.png' alt='line分享' title='line分享')
                  | 分享
            //- b-row
              b-col
                p 我的推薦碼： {{vipRecommendInfo.recommend_code}}
        div.btnGroup(align="center")
          //- .selectContentBtn.anniversary-select-content-btn.p-0(@click="showContent='recommendedanniversary'")
          //-   img.w-100(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-page-btn-new.svg' alt='')
          button.selectContentBtn(v-for='btn in btnGroup' :key='btn.value' :class="[showContent == btn.value? 'btnSelected' : '']" @click='showContent = btn.value')
            | {{btn.text}}
            i.fas.fa-angle-down.iconShow
        .wishPondBox
          .wishPondBoxTitleBox
            h4 {{$t('wishPondBox')}}
            | {{raffleGift[raffleGiftSlide].title}}
          .carouselBox
            b-carousel#carousel-2(v-model='raffleGiftSlide' :interval='4000' controls indicators style='text-shadow: 1px 1px 2px #333;')
              b-carousel-slide.giftImg(v-for='gift in raffleGift' :img-src='gift.giftImg' fluid)
            | {{raffleGift[raffleGiftSlide].giftName}}

          .wishPondBoxContentBox
            hr.wishPondHr
            span(v-html="raffleGift[raffleGiftSlide].content")

        .adContent
          a(href='https://wushare.tw/product/yotta-introduce-taiwan-in-english-sale-page/' target='_blank')
            //- img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/mgm-wushare-ad.jpg' alt='Wushare 廣告' title='Wushare 廣告')
            img.img-fluid(src='https://cdn2.wuwow.tw/pictures/mgms/sidebar-2023-main.svg' alt='Wushare 廣告' title='Wushare 廣告')
      b-col.leftSideBox(md='9')
        div
          //-  :vipRecommendList='vipRecommendList' :vipTicketList='vipTicketList' :vipAwardList='vipAwardList'
          recommendedrecords(v-show="showContent === 'records'" :vipRecommendInfo='vipRecommendInfo')
          recommendedmethod(v-show="showContent === 'method'" :vipRecommendInfo='vipRecommendInfo' :mgmRecommendUrl='mgmRecommendUrl' :prizesConfig='vipRecommendInfo.mgm_prizes_config')
          precautions(v-show="showContent === 'precaution'" :vipRecommendInfo='vipRecommendInfo')
          //- recommendedanniversary(v-show="showContent === 'recommendedanniversary'")
    input#vipMgmUrl(type='text' style='opacity:0;' :value="mgmRecommendUrl")
    //- // 複製網址用
</template>

<script type="text/javascript">
import { vipMgmLang } from '@/components/vipMgm/lang';
import Mgm from '@/components/wushare/leftMenuList';
import recommendedrecords from '@/components/vipMgm/recommendedRecords';
import recommendedmethod from '@/components/vipMgm/recommendedMethod';
import precautions from '@/components/vipMgm/precautions';
// import urlConstant from '@/constants/url';
// import recommendedanniversary from '@/components/vipMgm/recommendedAnniversary';
import customizedsharetext from '@/components/vipMgm/customizedShareText';
import moment from 'moment';

export default {
  name: 'Coupon',

  components: {
    Mgm,
    recommendedrecords,
    recommendedmethod,
    precautions,
    // recommendedanniversary,
    customizedsharetext,
  },

  i18n: vipMgmLang,

  props: {
    vipRecommendInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      deadlines: '2024-01-08 00:00:00',
      deadlinesAfter: false,
      showContent: 'records',
      slide: 0,
      raffleGiftSlide: 0,
      raffleGiftText: {
        title: '現階段獎項',
        content: '',
        wishSlide: 2,
        nextRaffleGiftImg: '',
        raffleGiftName: 'FREED尊爵刮鬍刀四件組',
      },
      leastTickets: [0, 30, 60, 100, 250, 500, 9999],
      raffleGift: [
        {
          title: '現階段獎項',
          giftImg: '',
          giftName: '',
          content: '',
        },
        {
          title: '下一階段獎項',
          giftImg: '',
          giftName: '',
          content: '',
        },
      ],
      // mgmInfoData: {},
      copiedMS: false,
      btnGroup: [
        {
          value: 'records',
          text: '我的推薦',
        },
        {
          value: 'method',
          text: '活動詳情',
        },
        {
          value: 'precaution',
          text: '注意事項',
        },
      ],
      // mgmRecommendUrl: urlConstant.VIP.MGM_RECOMMEND_URL,
      mgmRecommendUrl: `${process.env.VUE_APP_WUWOW_PORTAL_URL}event/regular_mgmevent_kid/`,
      mgmRecommendGoogleUrl: `${process.env.VUE_APP_WUWOW_PORTAL_URL}event/regular_mgmevent_google/`,
      vipMediaShareUrl: {
        lineLink:
          'http://line.naver.jp/R/msg/text/?WUWOW 學員活動 - WU朋引伴，作伙來 %0D%0Ahttps%3A%2F%2Fwww.wuwow.tw%2Fevent%2Fregular_mgmevent',
        fbDataHref: 'https://www.wuwow.tw/event/regular_mgmevent',
        fbLink:
          'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.wuwow.tw%2Fevent%2Fregular_mgmevent',
      },
      moment,
    };
  },

  watch: {
    vipRecommendInfo() {
      this.wishPondValue(this.vipRecommendInfo);
      this.setMediaShareUrl(this.vipRecommendInfo);
    },
  },
  created() {
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },
  async mounted() {
    this.openGiftStep(2);
  },

  methods: {
    // 設定現階段跟下階段獎項內容 左邊輪播
    wishPondValue(mgmInfo) {
      const totalTicket = mgmInfo.all_tickets_count; // 目前一共有多少張抽獎卷
      for (let i = 0; i <= this.leastTickets.length - 1; i++) {
        const min = this.leastTickets[i],
              max = this.leastTickets[i + 1];
        if (totalTicket >= min && totalTicket < max) {
          // 現階段獎項
          this.raffleGift[0].giftImg = mgmInfo.mgm_prizes_config.raffles[i].uri_path;
          this.raffleGift[0].giftName = mgmInfo.mgm_prizes_config.raffles[i].prize_name;
          this.raffleGift[0].content =
            'WUWOW Jr學員本月<br>已在許願池投入：<br class="m-br"><label><u> ' +
            totalTicket +
            ' </u>張抽獎券</label>';
          // 下一階段獎項
          this.raffleGift[1].giftImg = mgmInfo.mgm_prizes_config.raffles[i + 1].uri_path;
          this.raffleGift[1].giftName =
            mgmInfo.mgm_prizes_config.raffles[i + 1].prize_name;
          this.raffleGift[1].content =
            '學員們目前還差：<br class="m-br"><label><u> ' +
            (max - totalTicket) +
            ' </u>張抽獎券</label>';
        }
      }
    },
    setMediaShareUrl(mgmInfo) {
      // 設定複製網址
      this.mgmRecommendUrl += '?rc=' + mgmInfo.recommend_code;
      this.mgmRecommendGoogleUrl += '?rc=' + mgmInfo.recommend_code;
      // 設定分享網址
      this.vipMediaShareUrl.lineLink += '%3Frc%3D' + mgmInfo.recommend_code;
      this.vipMediaShareUrl.fbDataHref += '?rc=' + mgmInfo.recommend_code;
      this.vipMediaShareUrl.fbLink += '%3Frc%3D' + mgmInfo.recommend_code;
      // 有業務名稱才新增note
      if (mgmInfo.bd != null) {
        this.mgmRecommendUrl += '&nt=' + mgmInfo.bd.english_name;
        this.vipMediaShareUrl.lineLink += '%26nt%3D' + mgmInfo.bd.english_name;
        this.vipMediaShareUrl.fbDataHref += '&nt=' + mgmInfo.bd.english_name;
        this.vipMediaShareUrl.fbLink +=
          '%26nt%3D' + mgmInfo.bd.english_name + '&amp;src=sdkpreparse';
      }
    },
    copyUrl() {
      const copyText = document.getElementById('vipMgmUrl');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.copiedMS = true;
    },
    openContent(content) {
      this.showContent = content;
    },
    scrollToGift(dom) {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          setTimeout(() => {
            const stepDom = document.querySelector(dom);
            stepDom.scrollIntoView({ behavior: 'smooth', block: 'start' });
            resolve();
          }, 1);
        });
      });
    },
    clearSearchParams() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$router.replace({ name: 'vip-mgm', query: {}});
          resolve();
        }, 1);
      });
    },
    async openGiftStep(num) {
      const { step } = this.$route.query;
      if (step) {
        this.openContent(step);
        await this.clearSearchParams();
        await this.scrollToGift(`#gift-step${num}`);
        return;
      }
    },
    async scrollToMethodPage() {
      this.openContent('method');
      this.$nextTick(() => {
        this.scrollToGift(`.giftBtnGroup`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-subTitle {
  font-weight: 500;
  font-size: 16px;
  color: #151B1E;
  text-align: center;
   + a img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
   }
}
</style>
