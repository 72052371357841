var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "gift-step11"
    }
  }, [_c('b-container', [_c('b-row', {
    staticClass: "justify-content-center gift-step11-wrap"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('div', {
    staticClass: "anniversary_2023"
  }, [_c('h2', [_c('span', [_vm._v("好禮第1重")]), _c('br'), _vm._v("✨推薦好友領 LINE POINTS 200 點✨")]), _c('div', {
    staticClass: "anniversary_2023_content"
  }, [_c('div', {
    staticClass: "note"
  }, [_c('span', [_vm._v("2024/1/31")]), _vm._v("號，將會發放 LINE POINTS 點數"), _c('br'), _vm._v("記得在會員資料裡，填寫正確信箱才會收到點數哦！")]), _c('p', {
    staticClass: "note-info"
  }, [_vm._v("凡是"), _c('span', [_vm._v("2023/12/4~2024/01/07")]), _vm._v("活動期間推薦好友留下資訊，"), _c('br'), _vm._v("且好友符合活動限定「有效」條件資格即使好友無購買課程，"), _c('br'), _vm._v("你也能享有"), _c('span', [_vm._v("LINE POINTS 200")]), _vm._v("點哦！")]), _c('div', {
    staticClass: "recommend"
  }, [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/gift-2023-Introduce-linepoint.svg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/gift-2023-Introduce-free.svg"
    }
  })])])])])], 1), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }