var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('b-container', [_c('b-row', [_c('div', {
    staticClass: "titleBox"
  }, [_c('img', {
    attrs: {
      "align": "left",
      "src": "https://cdn2.wuwow.tw/pictures/mgms/title-sun.png",
      "alt": "太陽",
      "title": "太陽"
    }
  }), _c('span', {
    staticClass: "titleText text-center font-weight-bold"
  }, [_vm._v("「WU朋引伴，做伙來」活動規則")]), _c('img', {
    attrs: {
      "align": "right",
      "src": "https://cdn2.wuwow.tw/pictures/mgms/title-cloud.png",
      "alt": "雲",
      "title": "雲"
    }
  })])]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.deadlinesAfter && !_vm.vipRecommendInfo.is_buy_google ? _c('div', {
    staticClass: "anniversary2023Active"
  }, [_c('h2', {
    staticClass: "anniversary2023Active-title text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/mgm/anniversary_2023-title.svg"),
      "alt": "周年慶"
    }
  })]), _c('div', {
    staticClass: "anniversary2023Active-table"
  }, [_c('ul', {
    staticClass: "anniversary2023Active-tableHader"
  }, [_c('li'), _c('li', [_vm._v("週年慶加碼活動")])]), _c('ul', {
    staticClass: "anniversary2023Active-day"
  }, [_c('li', [_vm._v("活動日期")]), _c('li', [_vm._v("2023.12.4 ~ 2024.1.7")])]), _c('ul', {
    staticClass: "anniversary2023Active-target"
  }, [_c('li', [_vm._v("參加對象")]), _c('li', [_vm._v("不限在期學員，凡是購買過 WUWOW/ WUWOW JUNIOR的學員都可加入推薦。")])]), _c('ul', {
    staticClass: "anniversary2023Active-machine"
  }, [_c('li', [_vm._v("獎勵機制")]), _c('li', [_c('p', [_vm._v("推薦獎")]), _c('p', [_vm._v("幸運抽獎許願池推薦符合資格好友，"), _c('br'), _vm._v("再領"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("LINE POINTS 200點")])]), _c('p', [_vm._v("成交獎")]), _c('p', [_c('span', [_vm._v("獎金")]), _vm._v("依購買方案類型，獲得不同的推薦獎金或課程堂數。"), _c('br'), _vm._v("推薦人數無上限，單次最高可獲得推薦獎金"), _c('br'), _vm._v("從「 NT$ 6,000 」調漲至「"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("NT$ 6,500")]), _vm._v("」。"), _c('br'), _vm._v("詳細內容，歡迎於"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("推薦方法")]), _vm._v("頁面查看"), _c('img', {
    attrs: {
      "src": "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/mgms/money.svg",
      "alt": "money"
    }
  })])])])])]) : _vm._e(), !_vm.deadlinesAfter && _vm.vipRecommendInfo.is_buy_google ? _c('div', {
    staticClass: "anniversary2023Active"
  }, [_c('h2', {
    staticClass: "anniversary2023Active-title text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/mgm/anniversary_2023-title.svg"),
      "alt": "周年慶"
    }
  })]), _c('div', {
    staticClass: "anniversary2023Active-table"
  }, [_c('ul', {
    staticClass: "anniversary2023Active-tableHader google-mgm"
  }, [_c('li'), _c('li', [_vm._v("Google限定活動")]), _c('li', [_vm._v("週年慶加碼活動")])]), _c('ul', {
    staticClass: "anniversary2023Active-day"
  }, [_c('li', {
    staticClass: "d-none d-lg-block"
  }, [_vm._v("活動日期")]), _c('li', [_c('span', {
    staticClass: "d-lg-none"
  }, [_vm._v("活動日期")]), _vm._v("2023.12.4 ~ 2024.1.7")])]), _c('ul', {
    staticClass: "anniversary2023Active-target google-mgm"
  }, [_c('li', [_vm._v("參加對象")]), _c('li', [_vm._v("不限在期學員，凡是購買過 WUWOW/ WUWOW JUNIOR"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("Google 方案")]), _vm._v("學員都可加入推薦。")]), _c('li', [_vm._v("不限在期學員，凡是購買過 WUWOW/ WUWOW JUNIOR的學員都可加入推薦。")])]), _c('ul', {
    staticClass: "anniversary2023Active-machine google-mgm"
  }, [_c('li', [_vm._v("獎勵機制")]), _c('li', [_c('p', [_vm._v("推薦獎")]), _c('p', [_vm._v("幸運抽獎許願池，維持不變")]), _c('p', [_vm._v("成交獎")]), _c('div', {
    staticClass: "award-Details"
  }, [_c('p', [_c('span', [_vm._v("獎品 累進制")]), _c('br'), _vm._v("推薦 2 位加入，獲得新NT$2,000"), _c('br'), _vm._v("推薦 3 位加入，獲得新NT$4,500"), _c('br'), _vm._v("推薦 5 位加入，獲得新NT$10,000"), _c('br'), _vm._v("推薦 10 位加入，獲得新NT$30,000"), _c('br'), _vm._v("推薦 20 位加入，獲得新"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("NT$100,000")]), _c('br'), _c('br'), _vm._v("詳細內容，歡迎於"), _c('span', {
    staticClass: "text-scroll",
    on: {
      "click": function ($event) {
        return _vm.scrollTo('.scollSeven');
      }
    }
  }, [_vm._v("推薦方法")]), _vm._v("頁面查看")]), _c('p', [_c('span', [_vm._v("獎金")]), _vm._v("依購買方案類型，獲得不同的推薦獎金或課程堂數。"), _c('br'), _vm._v("推薦人數無上限，單次最高可獲得推薦獎金"), _c('br'), _vm._v("從「 NT$ 6,000 」調漲至「"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("NT$ 6,500")]), _vm._v("」。"), _c('br'), _vm._v("詳細內容，歡迎於"), _c('span', {
    staticClass: "text-scroll"
  }, [_vm._v("推薦方法")]), _vm._v("頁面查看"), _c('img', {
    attrs: {
      "src": "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/mgms/money.svg",
      "alt": "money"
    }
  })])])])])])]) : _vm._e()]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "activeRule"
  }, [_c('h2', {
    staticClass: "activeRule-title original",
    class: {
      close: !_vm.deadlinesAfter
    }
  }, [_vm._v("最新活動調整")]), _c('p', {
    staticClass: "activeRule-subTitle original",
    class: {
      close: !_vm.deadlinesAfter
    }
  }, [_vm._v(" 自2023/7/6 起制度更新，推薦成交獎品由人數累進獎品制度，改為每一次成交都有推薦獎金。")]), _c('h3', {
    staticClass: "activeRule-title"
  }, [_vm._v("新舊制度比較")]), _c('div', {
    staticClass: "activeRule-form"
  }, [_c('ul', {
    staticClass: "activeRule-formHeard"
  }, [_c('li'), _c('li', [_vm._v("舊制度")]), _c('li', [_vm._v("新制度")])]), _c('ul', {
    staticClass: "activeRule-day"
  }, [_c('li', [_vm._v("活動日期")]), _c('li', [_c('span', {
    staticClass: "d-lg-none"
  }, [_vm._v("活動日期")]), _c('br', {
    staticClass: "d-lg-none"
  }), _vm._v("2020.4.17-2023.7.5")]), _c('li', [_c('span', {
    staticClass: "d-lg-none"
  }, [_vm._v("活動日期")]), _c('br', {
    staticClass: "d-lg-none"
  }), _vm._v("2023.7.6 起")])]), _c('ul', {
    staticClass: "activeRule-target"
  }, [_c('li', [_vm._v("參加對象")]), _c('li', [_vm._v("不限在期學員，凡是購買過 WUWOW/ WUWOW JUNIOR的學員都可加入推薦。")])]), _c('div', {
    staticClass: "activeRule-machine"
  }, [_c('p', [_vm._v("獎勵機制")]), _c('ul', [_c('li', [_vm._v("推薦獎")]), _c('li', [_vm._v("幸運抽獎許願池，維持不變")]), _c('li', [_vm._v("成交獎")]), _c('li', [_c('p', [_vm._v("獎品 累進制"), _c('br'), _c('br'), _vm._v("推薦 1 位加入，獲得「Google Nest"), _c('br'), _vm._v("Mini 智慧音箱」"), _c('br'), _c('br'), _vm._v("推薦 3 位加入，獲得「Airpods」"), _c('br'), _c('br'), _vm._v("推薦 5 位加入，獲得「iPad」"), _c('br'), _c('br'), _vm._v("推薦 6 位 (含)以上，每推薦一人可獲得"), _c('br'), _vm._v("「台幣 NT$ 1,500」")]), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("獎金")]), _c('br'), _c('br'), _vm._v("依購買方案類型，獲得不同的推薦獎金或課程堂數。"), _c('br'), _vm._v("推薦人數無上限，單次最高可獲得推薦獎金「"), _c('span', {
    staticClass: "orange-color"
  }, [_vm._v("NT$ 6,000")]), _vm._v("」。"), _c('br'), _c('br'), _vm._v("詳細內容，歡迎於「"), _c('span', {
    staticClass: "orange-color"
  }, [_vm._v("推薦方法")]), _vm._v("」頁面查看。")])])])])])])]), _c('b-col', {
    staticClass: "mt-4"
  }, [_c('ul', {
    staticClass: "activeDescription"
  }, [_c('li', {
    staticClass: "mb-4"
  }, [_c('h3', [_vm._v("一、活動目的：")]), _c('p', [_vm._v("讓更多想要學習英文的人，一起加入 WUWOW / WUWOW JUNIOR 共學英文！")])]), _c('li', {
    staticClass: "mb-4"
  }, [_c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("二、活動參加資格：")]), _c('p', {
    staticClass: "activeDescription-item mb-1",
    attrs: {
      "data-count": "1."
    }
  }, [_vm._v("一日 WUWOW 人，終身 WUWOW 魂！"), _c('br'), _vm._v("凡曾為 WUWOW / WUWOW JUNIOR 之合約開通之學員，不限合約是否在期，皆可參與「 WU 朋引伴，做伙來」親友推薦。")]), _c('p', {
    staticClass: "activeDescription-item mb-1",
    attrs: {
      "data-count": "2."
    }
  }, [_vm._v("2022.12.9 起活動參加資格變更，2022.12.9 前，僅限合約開通日起之「在期」學員參與活動；"), _c('br'), _vm._v("2022.12.9 後，不限合約時間，只要曾經為 WUWOW / WUWOW JUNIOR 學員，皆符合推薦好友資格。但不回補追朔歷年「合約已到期」之學員所推薦的任何活動獎項資格。")]), !_vm.deadlinesAfter ? _c('p', {
    staticClass: "activeDescription-item mb-1",
    attrs: {
      "data-count": "3."
    }
  }, [_vm._v("2023.12.04~2024.01.07 週年慶加碼期間，學員推薦符合條件資格的人填寫名單"), _c('span', {
    staticClass: "text-scroll curpointer",
    on: {
      "click": function ($event) {
        return _vm.scrollTo('.scollFive');
      }
    }
  }, [_vm._v("(參考B項第5點)")]), _vm._v("，「推薦者」即可獲得 Line Points $200元"), _c('br'), _vm._v("且若「被推薦者」購買指定方案課程，可得最高「加贈45堂(WUWOW) 或 加贈15堂(JR)」英文課程。"), _c('span', {
    staticClass: "text-scroll curpointer",
    on: {
      "click": function ($event) {
        return _vm.scrollTo('.scollSix');
      }
    }
  }, [_vm._v("(參考B項第6點)")]), _vm._v("呈上，推薦成功的「推薦者」還有機會獲得最高推薦獎金$6,500元。"), _c('span', {
    staticClass: "text-scroll curpointer",
    on: {
      "click": function ($event) {
        return _vm.scrollTo('.scollSix');
      }
    }
  }, [_vm._v("(參考B項第6點)")])]) : _vm._e(), !_vm.deadlinesAfter ? _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "4."
    }
  }, [_vm._v("該活動「推薦獎金」 LINE POINTS 將於 2024/1/31 進行信箱發放，記得確保會員資料的信箱為您的常用收件信箱，避免遺失 LINE POINTS 哦！(任何因資訊錯誤導致遺失 LINE POINTS 領取，皆不做補發)"), _c('br'), _vm._v("該活動「成交獎金」領取相關時程，請注意推薦成交成功後的「通知信件」，繳回相關「獎金領據」等領獎流程，將可依序獲得獎金。")]) : _vm._e(), !_vm.deadlinesAfter ? _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "5."
    }
  }, [_vm._v("Google 企業專屬推薦活動：2023.12.04~2024.01.07 週年慶加碼期間，學員推薦符合條件資格的人填寫名單 (參考B項第5點) ，「推薦者」即可獲得 Line Points $200元，且若「被推薦者」購買指定方案課程，「推薦者」可得最高「$100,000」推薦成功獎金。 (參考B項第7點)。")]) : _vm._e()]), _c('li', {
    staticClass: "mb-4"
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v("三、注意事項：")]), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("A. 重要活動注意事項")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "1."
    }
  }, [_vm._v("自 2023.7.6 起，成交獎由獎品制改為獎金制度，於該日之前推薦之名單若有成交，比照舊制獎勵處理。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "2."
    }
  }, [_vm._v("所有推薦、獎勵紀錄與發放，可於「我的推薦」中的「推薦紀錄」查看，若該名好友資訊填入有誤、無法聯繫、非本人、非經學員推薦、重複名單，則不予以發放抽獎券、且不計入推薦人數。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "3."
    }
  }, [_vm._v("被推薦人透過專屬連結完成預約體驗，即表示同意 WUWOW / WUWOW JUNIOR 可將其姓名(部分文字隱藏)、課程體驗日期、購買進度與否之資訊與推薦人知悉。")]), _c('p', {
    staticClass: "activeDescription-item mb-3",
    attrs: {
      "data-count": "4."
    }
  }, [_vm._v("WUWOW 品牌方擁有本活動的最終解釋權，並有權隨時修改、變更、取消本活動。")]), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("B. 推薦注意事項")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "1."
    }
  }, [_vm._v("若同一名學員有大於兩人以上之推薦者，則獎項歸屬於首位推薦者。")]), _c('div', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "2."
    }
  }, [_vm._v("本活動僅認列含有推薦碼以及透過「WU朋引伴，做伙來」頁面填寫之資料，且亦不認列下方三種情形："), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(1)"
    }
  }, [_vm._v(" 填寫「WU朋引伴，做伙來」之前，被推薦人已曾經購買 WUWOW / WUWOW JUNIOR 課程方案")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(2)"
    }
  }, [_vm._v("填寫「WU朋引伴，做伙來」之前，被推薦人已與課程顧問正在溝通階段，則推薦不予計算。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(3)"
    }
  }, [_vm._v(" 「WU朋引伴，做伙來」之後，被推薦人拒絕本次推薦，後續透過其他方式加入 WUWOW / WUWOW JUNIOR，則推薦不予計算。")])]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "3."
    }
  }, [_vm._v("若 WUWOW / WUWOW JUNIOR 收到大量惡意灌水之推薦名單，經 WUWOW 品牌方判斷證實，將終止該學員「 WU 朋引伴 」推薦、獎項領取資格，並須就被推薦人以及 WUWOW 品牌方之相關損害負完全賠償責任。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "4."
    }
  }, [_vm._v("WUWOW 品牌方將以信件聯繫每月抽將活動中獎學員、以及符合成功推薦獎勵資格之學員，與學員確認獎項寄送細項後，始予以頒發獎項。")]), _c('div', {
    staticClass: "activeDescription-item scollFive",
    attrs: {
      "data-count": "5."
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("推薦獎")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(1)"
    }
  }, [_vm._v("推薦名單經 WUWOW / WUWOW JUNIOR 專員聯繫（約需6~7個工作天）完成確認後，始予以發放抽獎券，歡迎至個人推薦紀錄中查詢狀態。")])]), _c('div', {
    staticClass: "activeDescription-item scollSix mb-2",
    attrs: {
      "data-count": "6."
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("成交獎")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(1)"
    }
  }, [_vm._v("成交獎領取資格，需於被推薦人「合約起始日一個月」後始確認成交，確認完畢後 WUWOW / WUWOW JUNIOR 將發放推薦人與被推薦人之獎勵。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(2)"
    }
  }, [_vm._v("被推薦人購買方案須為 WUWOW / WUWOW JUNIOR 「一般課程」方案。"), _c('br'), _vm._v("若是購買：每日一堂方案、企業方案（例：Google、克蘭詩）、團購方案... ...等，不予計算「推薦者」成功推薦人數（故成交獎資格皆無效）。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(3)"
    }
  }, [_vm._v("被推薦人經推薦加入 WUWOW / WUWOW JUNIOR 後，若合約期滿再次購買方案，不予以重複計算推薦人之成功推薦人數，每位被推薦者僅可計算成功推薦人1次。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(4)"
    }
  }, [_vm._v("分享/轉讓不視為購買，被推薦者獲現有學員之分享/轉讓，不予計算成功推薦人數。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(5)"
    }
  }, [_vm._v("若被推薦人60日內經聯繫後結案，之後由其他管道再次進入聯繫環節，就算成交，亦不算被推薦名單，原有之推薦人無法領取成交獎勵。")])]), !_vm.deadlinesAfter ? _c('div', {
    staticClass: "activeDescription-item scollSeven mb-3",
    attrs: {
      "data-count": "7."
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("Google 企業專屬成交獎")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(1)"
    }
  }, [_vm._v("成交獎領取資格，需於被推薦人「合約起始日一個月」後始確認成交成功，確認完畢後 WUWOW / WUWOW JUNIOR 將發放推薦人之獎勵。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(2)"
    }
  }, [_vm._v("被推薦人購買方案須為 WUWOW / WUWOW JUNIOR 「Google企業方案」課程。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(3)"
    }
  }, [_vm._v("「被推薦者」者僅限首次購買「Google企業方案」課程，方為推薦成交成功；「推薦者」續約購買「Google企業方案」則可計推薦成交成功1次。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(4)"
    }
  }, [_vm._v("分享/轉讓不視為購買，被推薦者獲現有學員之分享/轉讓，不予計算成功推薦人數。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(5)"
    }
  }, [_vm._v("若被推薦人60日內經聯繫後結案，之後由其他管道再次進入聯繫環節，就算成交，亦不算被推薦名單，原有之推薦人無法領取成交獎勵。")])]) : _vm._e(), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("C. 活動獎項領取注意事項：")]), _c('div', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "1."
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("推薦獎")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(1)"
    }
  }, [_vm._v("每月抽獎活動之抽獎券於次月 6 號結算，確定抽獎獎項、每位學員抽獎券數量後，方抽出中獎學員名單。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(2)"
    }
  }, [_vm._v("推薦獎項根據名單被推薦的時間決定，將如表訂獎項寄出，若有不可抗力因素須更換獎項 (如斷貨、天災等...)，WUWOW 品牌方將於通知後調整為等值之商品或延後寄送時間。")])]), _c('div', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "2."
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("成交獎")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(1)"
    }
  }, [_vm._v("系統判定確立成交獎成立後，會以系統信件要求推薦者回傳「中獎領據」及「收款帳戶資 料」，待學員填寫完整、回傳，WUWOW 品牌方最遲將於收到完整資訊後的次月 16 號出款；倘若於「中獎領據」信寄出後 1 個月內，未將領據回傳，視同放棄領獎資格。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(2)"
    }
  }, [_vm._v("推薦人之推薦獎金，可兌換等值課程堂數或延長會籍期限。")]), _c('p', {
    staticClass: "activeDescription-item",
    attrs: {
      "data-count": "(3)"
    }
  }, [_vm._v("推薦人與被推薦人經推薦加入 WUWOW / WUWOW JUNIOR 所獲得之免費堂數，與正式堂數擁有同等效力，可預訂 1 on 1家教、1 - 3人微班級、隨選隨上、百家書苑，不受任何額外限制。")])])])])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-5",
    attrs: {
      "align": "right"
    }
  }, [_vm._v("WUWOW / WUWOW JUNIOR 行銷部")])], 1)], 1)], 1), _c('b-img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/mgm-footer.png",
      "fluid": "",
      "alt": "頁尾圖片",
      "title": "頁尾圖片"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }