var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gift-content-one",
    attrs: {
      "id": "gift-step1"
    }
  }, [_c('b-container', [_c('b-row', {
    staticClass: "title-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v("第一重好禮")]), _c('h1', [_vm._v("✨幸運抽獎許願池✨")]), _c('h4', [_vm._v("（每月開獎）")])])])], 1), _c('b-row', {
    staticClass: "mb-5",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "text-center row-margin"
  }, [_c('p', [_vm._v("只要親友透過"), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("你的專屬推薦頁面")]), _c('span'), _vm._v("預約試聽"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("即可獲得當月抽獎券！累積越多抽獎券"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("不但獎項越豪華，中獎機率也越高噢！"), _c('br'), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("（當月抽獎卷當月抽出，不會累積到隔月噢！）")])])])], 1), _vm.deadlinesAfter ? _c('div', {
    staticClass: "childrenDay"
  }, [_c('h2', [_vm._v("歡慶"), _c('span', [_vm._v("兒童節")]), _vm._v("加碼啦！")]), _c('h3', [_vm._v("活動限時 2024/4/1 ~ 2024/4/14")]), _c('p', [_vm._v("於活動期間內，"), _c('br'), _vm._v("推薦好友成功，推薦者可得"), _c('span', [_vm._v("《下午茶卷》")]), _vm._v("！")]), _c('img', {
    attrs: {
      "src": require('@/assets/childrensday2024/mgm.png'),
      "alt": "childrenDay"
    }
  }), _c('article', [_c('p', [_vm._v("備註:")]), _c('ul', [_c('li', [_vm._v("1. 有效好友名單判定資格如【注意事項】中的「B-5推薦獎」規則。")]), _c('li', [_vm._v("2. 午茶卷獎勵發放時間為：2024/4/30 前採信件統一寄送獎勵序號。")]), _c('li', [_vm._v("3. 若因消費者填寫錯誤信箱資訊導致獎勵遺失，則不補發。")])])])]) : _vm._e(), _c('h3', {
    staticClass: "text-center row-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_vm._v("如何得到抽獎券？")]), _c('b-row', {
    staticClass: "row-margin"
  }, [_c('b-col', {
    attrs: {
      "data-aos": "fade-up-right",
      "data-aos-duration": "1000",
      "data-aos-once": "true",
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "imgBox color_1 ticket"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/ticket-two.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("推薦第1位"), _c('br'), _vm._v("獲得2張抽獎券")])])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "imgBox color_1 ticket"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("推薦第2~9位"), _c('br'), _vm._v("獲得1張抽獎券")])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "imgBox color_1 ticket"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/ticket-three.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("推薦第10位"), _c('br'), _vm._v("獲得3張抽獎券")])])]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "imgBox color_1 ticket"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("推薦第11位以上"), _c('br'), _vm._v("獲得1張抽獎券")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "data-aos": "fade-up-left",
      "data-aos-duration": "1000",
      "data-aos-once": "true",
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "ticket-caption"
  }, [_c('h4', {
    staticClass: "mb-3"
  }, [_c('span', {
    staticClass: "highlight"
  }, [_vm._v("推薦越多，得獎機率越高！")])]), _c('p', [_vm._v("推薦第1位朋友，"), _c('br', {
    staticClass: "m-br"
  }), _vm._v("獲得2張抽獎券")]), _c('p', [_vm._v("推薦第2-9位朋友，"), _c('br', {
    staticClass: "m-br"
  }), _vm._v("獲得1張抽獎券")]), _c('p', [_vm._v("推薦第10位朋友，"), _c('br', {
    staticClass: "m-br"
  }), _vm._v("獲得3張抽獎券")]), _c('p', [_vm._v("推薦第11位(含)以上朋友，"), _c('br', {
    staticClass: "m-br"
  }), _vm._v("各將獲得1張抽獎券")]), _c('p', {
    staticClass: "text-center my-4"
  }, [_vm._v("例：小wu在同一個月推薦了13位朋友，所以小wu可以在當月拿到16張抽獎券呦！")])])])], 1), _c('h3', {
    staticClass: "text-center row-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_vm._v("抽獎許願池怎麼玩？")]), _c('b-row', {
    staticClass: "row-margin"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true",
      "cols": "12",
      "md": "12"
    }
  }, [_c('p', [_vm._v("根據WUWOW Jr在"), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("當月累計發送給全體學員的抽獎券數量")]), _vm._v("，抽出相對應的獎項，"), _c('br', {
    staticClass: "m-br1"
  }), _vm._v("累積越多，好禮越大，快點樓頂揪樓下，旁邊揪旁邊，一起WU朋引伴作伙來吧！")])]), _vm._l(_vm.giftImgGroup, function (img, index) {
    return _c('b-col', {
      key: img.role_no,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-duration": "1000",
        "data-aos-delay": "500",
        "data-aos-once": "true",
        "cols": "6",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "imgBox",
      class: index % 2 == 0 ? 'color_1' : 'color_2'
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": img.uri_path
      }
    })]), _c('p', {
      staticClass: "gift",
      domProps: {
        "innerHTML": _vm._s(img.prize_name)
      }
    })]);
  })], 2), _c('b-row', {
    staticClass: "row-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "rule-caption"
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "highlight"
  }, [_vm._v("推薦越多，得獎機率越高！")])]), _c('p', [_vm._v("◆ 當月WUWOW Jr發送了1-29張抽獎券，將抽出 " + _vm._s(_vm.firstAward))]), _c('p', [_vm._v("◆ 當月WUWOW Jr發送了30-59張抽獎券，將抽出 " + _vm._s(_vm.secondAward))]), _c('p', [_vm._v("◆ 當月WUWOW Jr發送了69-99張抽獎券，將抽出 " + _vm._s(_vm.thirdAward))]), _c('p', [_vm._v("◆ 當月WUWOW Jr發送了100-249張抽獎券，將抽出 " + _vm._s(_vm.fourthAward))]), _c('p', [_vm._v("◆ 當月WUWOW Jr發送了250-499張抽獎券，將抽出 " + _vm._s(_vm.fifthAward))]), _c('p', [_vm._v("◆ 當月WUWOW Jr發送了500張(含)以上抽獎券，將抽出 " + _vm._s(_vm.sixthAward))]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("例：當月WUWOW Jr發送給全體學員149張抽獎券，"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("那麼在這個月將會抽出" + _vm._s(_vm.fourthAward) + "喲！")])])])], 1), _vm._t("default"), _c('b-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "rightNowBox text-center anniversary-right-now-box pb-0"
  }, [_c('h3', [_vm._v("你還在等什麼？")]), _c('div', {
    staticClass: "rightNowBtn",
    attrs: {
      "id": "rightNowBtn1",
      "data-aos": "zoom-in",
      "data-aos-duration": "500",
      "data-aos-delay": "1000",
      "data-aos-once": "true"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.mgmRecommendUrl,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v("立刻開揪")])])]), _c('p', [_vm._v("心動不如馬上行動，"), _c('br'), _vm._v("一起來挑戰最大獎 Macbook Pro！")]), _c('div', {
    staticClass: "shareContent w-100"
  }, [_c('customizedShareText', {
    attrs: {
      "usePage": 'giftContentAnniversary'
    }
  })], 1)])])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }