export default {
  // API的參數
  API_PARAM: {
    // 待與後端確認
    AC: {
      RECORD: 'record', // 推薦紀錄
      TICKET: 'tickets', // 抽獎券紀錄
    },
  },

  // 畫面分類
  RECORD_TYPE: {
    RECOMMEND: 'recommend', // 推薦紀錄
    TICKET: 'ticket', // 抽獎券紀錄
    AWARD: 'award', // 抽獎券紀錄
  },

  // 選擇查看api日期的選項
  DATE_TYPE: {
    ALL: 0, // 查看全部
    TODAY: 1, // 今天
    YESTERDY: 2, // 昨天
    PAST_SEVEN_DAYS: 3, // 過去七天
    THIS_MONTH: 4, // 這個月
    LAST_MONTH: 5, // 上個月
    CUSTOM_SET_DATE: 6, // 自訂日期
  },
};
