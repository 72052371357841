<template lang="pug">
div
    .wuwow-card
      .wuwow-card-body
        b-container
          b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='500' data-aos-once='true')
            b-col
              div
                picture
                  source(srcset='https://cdn2.wuwow.tw/pictures/mgms/recommend-method-title-mobile.png' media='(max-width: 768px)' alt='推薦方法標題' title='推薦方法標題')
                  img.img-fluid(srcset='https://cdn2.wuwow.tw/pictures/mgms/recommend-method-title-pc.png' alt='推薦方法標題' title='推薦方法標題')
          br
          br
          //- b-row.justify-content-md-center(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true')
          //-   b-col(v-for='(step, index) in stepGroup' :key='`stepGroup_${index}`' col='' lg='3')
          //-     img.stepImg(fluid='' :src='step.imgUrl' :alt='step.title' :title='step.title')
          //-     p.text-center.step-text(v-html='step.content')
          b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1500' data-aos-once='true')
            b-col
              .recommendContentPC.text-center
                p
                  | 你身邊也有想學英文的親朋好友嗎？
                  br
                  | 不論你是 WUWOW 還是 WUWOW Jr 學員
                  br.pc-br
                  | 現在推薦他們一起學英文，被推薦的親友就可以
                  br
                  span.highlight 獲贈額外課程堂數！
                  br
                  br
                  | 熱心推薦的你更可以獲得
                  span.highlight  優渥的推薦獎金，
                  //- span.highlight
                  //-   span(v-for='(item, index) in dealAward.slice(0, 3)' :key="`dealAward_${index}`") {{item.prize_name}}、
                  //-   | 獎金等好禮！
                  //- br
                  //- | 讓你做足面子，更贏了裡子！
                  //- br
                  //- | 一起揪團學英文～
          b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1600' data-aos-once='true')
            b-col(class="text-center")
              .anniversary-2023-end
                .anniversary-2023-end-main
                  h2 週年慶加碼活動結束囉
                  p
                    | LINE POINTS 200 點
                    br
                    | 將會於
                    span 2024/1/31
                    | 日進行發放
                    br
                    | 相關推薦紀錄請至「
                    span 我的推薦
                    | 」查詢
                  img(src="https://cdn2.wuwow.tw/pictures/mgms/getPoint-left.png" alt="")
                  img(src="https://cdn2.wuwow.tw/pictures/mgms/getPoint-right.png" alt="")
          b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1600' data-aos-once='true')
            b-col
              .anniversary-tips.mt-2.mb-5.text-center
                span.position-relative
                  | 快去寫下你的推薦心得，一鍵複製給親朋好友
                  br
                  | 讓更多人一起同樂，還能提升你的中獎率哦！
                  img.position-absolute(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-tips-icon.png' alt='')
          //- b-row(v-if="!deadlinesAfter && vipRecommendInfo.is_buy_google" data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1600' data-aos-once='true')
          //-   b-col
          //-     .google-recommend
          //-       h2 Google 專屬推薦
          //-       img(src="https://cdn2.wuwow.tw/pictures/mgms/google-2023.png" alt="Google 專屬推薦" title="Google 專屬推薦" class="img-fluid")
          //-       div
          //-         a.btn(type="button" :href="mgmRecommendGoogleUrl" target="_blank") 立即了解
          //-       h2 好友推薦
          //- b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-delay='1700' data-aos-once='true')
          //-   b-col
          //-     .overweight-btn.recommendInfo-page-ad.page-ad.mb-5(@click='giftContent= 3, scrollToGift()')
          //-       img.d-none.d-md-block.w-100(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-method-ad-after-window.png' alt='' style="box-shadow: none")
          //-       //- 手機板的照片
          //-       img.d-block.d-md-none.w-100(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-method-ad-after-mobile.png' alt='' style="box-shadow: none")
          b-row.giftBtnGroup(align-h='center' data-aos='fade-up' data-aos-duration='500' data-aos-delay='500' data-aos-once='true')
            b-col(lg='4' md='4' cols='12' class="mb-5 mb-md-0 px-2" v-for='(gift, index) in giftBtns' :key='`giftBtn_${index}`')
              a
                .giftBtn.anniversary_2023(@click='giftContent=gift.index, scrollToGift()' :id="'gift_btn'+gift.index")
                  p
                    | 好禮
                    span.anniversary-title {{gift.title}}
                    br
                    div.gifts(v-html="gift.content")
                  p.btn-read 立即了解
                  img.img-fluid(:src='gift.imgUrl' alt='')
                  //- .anniversary_2023_tip(v-if="!deadlinesAfter && [1,3].includes(gift.index)")
                  //-   img(src="https://cdn2.wuwow.tw/pictures/mgms/linepints.svg")
                  //-   | 好禮加碼
                  //- template(v-if="gift.index === 1 && !deadlinesAfter")
                  //-   p
                  //-     span.anniversary-title {{gift.title}}
                  //-     br
                  //-     div.gifts(v-html="gift.content")
                  //-   p.btn-read 立即了解
                  //-   img.img-fluid(:src='gift.imgUrl' alt='')
                  //- template(v-else)
                  //-   p
                  //-     | 好禮
                  //-     span.anniversary-title {{gift.title}}
                  //-     br
                  //-     div.gifts(v-html="gift.content")
                  //-   p.btn-read 立即了解
                  //-   img.img-fluid(:src='gift.imgUrl' alt='')
        #gift-content
          gift-content-one(v-if='giftContent === 1' :currentAwardList='prizesConfig' :mgmRecommendUrl='mgmRecommendUrl')
          gift-content-two(v-if='giftContent === 2' :currentAwardList='prizesConfig' :mgmRecommendUrl='mgmRecommendUrl')
      //- template(v-if="!deadlinesAfter")
      //-   gift-content-anniversary-twenty-twenty-three(v-if='giftContent === 1')
      //-   gift-content-one(v-if='giftContent === 2' :currentAwardList='prizesConfig' :mgmRecommendUrl='mgmRecommendUrl')
      //-   gift-content-two(v-if='giftContent === 3' :currentAwardList='prizesConfig' :mgmRecommendUrl='mgmRecommendUrl')
      //- template(v-else)
      //-   gift-content-one(v-if='giftContent === 1' :currentAwardList='prizesConfig' :mgmRecommendUrl='mgmRecommendUrl')
      //-   gift-content-two(v-if='giftContent === 2' :currentAwardList='prizesConfig' :mgmRecommendUrl='mgmRecommendUrl')
      //- gift-content-anniversary(v-if='giftContent === 3' :mgmRecommendUrl='mgmRecommendUrl')
      recommend-step
      b-img(src='https://cdn2.wuwow.tw/pictures/mgms/mgm-footer.png' fluid='' alt='頁尾圖片' title='頁尾圖片')

</template>

<script type="text/javascript">
import LearnRuleModal from '@/components/wushare/learnRuleModal';
import GiftContentOne from '../giftContentOne';
import GiftContentTwo from '../giftContentTwo';
import GiftContentAnniversary from '../giftContentAnniversary';
import GiftContentAnniversaryTwentyTwentyThree from '../giftContentAnniversaryTwentyTwentyThree';
import RecommendStep from '../recommendStep';
import { toNumber } from 'lodash';
import moment from 'moment';
export default {
  name: 'RecommendedMethod',

  components: {
    LearnRuleModal,
    GiftContentOne,
    GiftContentTwo,
    GiftContentAnniversary,
    GiftContentAnniversaryTwentyTwentyThree,
    RecommendStep,
  },
  props: {
    mgmRecommendUrl: {
      type: String,
      default: function() {
        return '';
      },
    },
    prizesConfig: {
      type: Object,
      default: function() {
        return {};
      },
    },
    vipRecommendInfo: { // 推薦方法
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      deadlines: '2024-02-06 17:00:00',
      deadlinesAfter: false,
      mgmRecommendGoogleUrl: `${process.env.VUE_APP_WUWOW_PORTAL_URL}event/regular_mgmevent_google/`,
      stepGroup: [
        {
          title: 'step1',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step1.png',
          content: '<h4>Step1</h4>推薦人取得<br>專屬推薦連結/代碼',
          index: 1,
        },
        {
          title: 'step2',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step2.png',
          content: '<h4>Step2</h4>將專屬推薦連結/代碼<br>分享給好友',
          index: 2,
        },
        {
          title: 'step3',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step3.png',
          content: '<h4>Step3</h4>好友透過專屬推薦<br>線上完成申請',
          index: 3,
        },
        {
          title: 'step4',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/step4.png',
          content: '<h4>Step4</h4>與好友一起享好禮',
          index: 4,
        },
      ],
      giftBtnGroup: [
        {
          title: '第1重',
          content: '<p>幸運抽獎許願池</p>',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/gift-one-btn.png',
          index: 1,
        },
        {
          title: '第2重',
          content: '<p>成功推薦wu獎金<p>',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/gift-two-btn.png',
          index: 2,
        },
      ],
      giftBtnGroupAnniversary: [
        {
          title: '周年慶特殊好禮',
          content: '<p>推薦好友領 200<br/><span class="line-point">LINE POINTS</span></p>',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/gift-one-2023-btn.svg',
          index: 1,
        },
        {
          title: '第1重',
          content: '<p>幸運抽獎許願池</p>',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/gift-one-btn.png',
          index: 2,
        },
        {
          title: '第2重',
          content: '<p>成功推薦wu獎金<br/><span>每筆<span class="text-encode-blue">加碼</span>最高<span class="encode">$6500</span>無上限</span></p>',
          imgUrl: 'https://cdn2.wuwow.tw/pictures/mgms/gift-two-btn.png',
          index: 3,
        },
      ],
      giftContent: 0,
      dealAward: [],
    };
  },

  computed: {
    giftBtns: function() {
      return this.giftBtnGroup;
    },
  },

  watch: {
    prizesConfig() {
      this.dealAward = this.prizesConfig.deals;
    },
    vipRecommendInfo(n) {
      this.mgmRecommendGoogleUrl += '?rc=' + n.recommend_code;
    },
  },
  created() {
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },
  mounted() {
    const { step, q } = this.$route.query;
    if (step) {
      this.giftContent = toNumber(q);
    }
  },
  methods: {
    scrollToGift() {
      const gift = document.querySelector('#gift-content');
      gift.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
  },
};
  </script>

  <style lang="scss" scoped>
  @mixin sm {
      @media (min-width: 475px) {
        @content;
      }
  }

  @mixin md {
      @media (min-width: 768px) {
        @content;
      }
  }
  @mixin lg {
      @media (min-width: 1024px) {
        @content;
      }
  }

  @mixin xl {
      @media (min-width: 1240px) {
        @content;
      }
  }
  @mixin xxl {
      @media (min-width: 1680px) {
        @content;
      }
  }

  @mixin xxxl {
      @media (min-width: 1920px) {
        @content;
      }
  }
  .anniversary-tips{
    img.banner{
      margin-top: 1em;
      max-width: calc(288.75px * 2 + 15px);
    }
  }
  .anniversary-title {
    font-size: 16px;
    @include xl {
      font-size: 24px;
    }
  }
.google-recommend {
  text-align: center;
  h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 40px;
  }
  img {
    margin-bottom: 20px;
    aspect-ratio: 488/196;
    width: 488px;
  }
  a {
    display: inline-block;
    background: #40210F;
    border: 2px solid #153C84;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 40px;
  }
}
.anniversary-2023-end {
    text-align: center;
    margin-bottom: 60px;
    background: rgba(247, 240, 217, 1);
    border-radius: 10px;
    padding: 24px 0;
    margin: 0 -20px 60px -20px;
    @include md {
        margin: 0 0px 60px 0px;
    }
}
.anniversary-2023-end-main {
    display: inline-block;
    position: relative;
    h2 {
        font-weight: 700;
        font-size: 21px;
        line-height: 30.41px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        color: rgba(51, 51, 51, 1);
        @include xl {
            line-height: 40.54px;
            font-size: 28px;
            margin-bottom: 20px;
        }
    }
    p {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 0;
        > span {
            font-weight: 700;
            color: rgba(240, 165, 71, 1);
        }
    }
    img {
        aspect-ratio: 1/1;
        position: absolute;
        width: 60px;
        @include md {
          width: 100px;
        }
        @include lg {
            width: 140px;
        }
        &:nth-of-type(1) {
            bottom: 0;
            left: 0;
            transform: translateX(-85%);
            @include lg {
                transform: translate(-110%, 10px);
            }
        }
        &:nth-of-type(2) {
            bottom: 0;
            right: 0;
            transform: translateX(85%);
            @include lg {
                transform: translate(110%, 10px);
            }
        }
    }
}
</style>

