const vipMgmLang = {
  messages: {
    // TODO: 待翻譯
    tw: {
      wishPondBox: '幸運抽獎許願池',
      inviteFriend: '現在開始邀請好友',
      copySuccess: '複製成功!!',
      copyUrl: '複製網址',
      share: '分享',
      myRecommendCode: '我的推薦碼：',

      winningRate: '我的中獎率',
      myTicket: '我的有效抽獎劵',
      currentPeriodTickets: '全體總抽獎劵',
      countReferrerMonth: '當月已推薦',
      countdownToDraw: '開獎倒數',
      piece: '張',
      people: '位',
      day: '天',

    },
    en: {
    },
  },
};

export {
  vipMgmLang
};
