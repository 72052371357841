<template lang="pug">
<!-- 2023周年慶增加區塊 -->
.customized-share-text
  h2 好友推薦
  div
    .share-text(:class="focusShareText?'share-text-focus':''")
      textarea(name='' id='' @focus='focusTextarea()' @focusout='focusTextarea()' v-model='vipShareText')
      .disable-text.p-2(v-html='defaultShareText')
    .shareBtns
      .mt-2.d-flex.justify-content-end
        button.btn.copyBtn.btn-sm.mr-2.text-dark(:id="`share-tooltip-target-${usePage}`" type='button' @click='copyShareText()' @mouseout='copiedShare=false') 複製文字
          b-tooltip(:show='copiedShare' :target="`share-tooltip-target-${usePage}`" triggers='manual') 複製成功
        button.btn.saveBtn.btn-sm(type='button' @click='saveShareText()') 儲存
  .shareBtnGroup.px-0
    .copyUrl.shareBtn(v-show="usePage === 'leftSideBar'" :id='`tooltip-target-${usePage}`' @click='copyUrl' @mouseout='copiedMS=false')
      b-tooltip(:show='copiedMS' :target='`tooltip-target-${usePage}`' triggers='click')
        | 複製成功!!
      | 複製網址
</template>
<script>
import LioshutanApi from '@/api';
import moment from 'moment';

export default {
  data() {
    return {
      deadlines: '2024-01-08 00:00:00',
      deadlinesAfter: false,
      focusShareText: false,
      defaultVipShareText: `我們家寶貝英文越來越好了！\n現在來這裡學，價格超優惠耶\n`,
      defaultShareText: '',
      vipShareText: '',
      copiedShare: false,
      mgmInfo: {},
      copiedMS: false,
      vipMediaShareUrl: {
        lineLink:
          'http://line.naver.jp/R/msg/text/?WUWOW 學員活動 - WU朋引伴，作伙來 %0D%0Ahttps%3A%2F%2Fwww.wuwow.tw%2Fevent%2Fregular_mgmevent',
        fbDataHref: 'https://www.wuwow.tw/event/regular_mgmevent',
        fbLink:
          'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.wuwow.tw%2Fevent%2Fregular_mgmevent',
      },
    };
  },
  props: {
    usePage: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  created() {
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },
  async mounted() {
    await this.getShareContents();
    await this.getVipRecommendInfo();
  },
  methods: {
    async getShareContents(){
      const params = {
        platform: 'wuwow_junior',
        isEnable: 'ignore',
      };
      const result = await LioshutanApi.vipMgm.getVipShareContents(params);
      const shareContent = result.data.data.share_content;
      this.vipShareText = (shareContent.length === 0) ? this.defaultVipShareText : shareContent[0].content;
    },
    async getVipRecommendInfo(){
      const result = await LioshutanApi.vipMgm.getVipRecommendInfo();
      this.mgmInfo = result.data.data.mgm_info;
      // // 暑期活動結束後打開
      const linkUrl = `${process.env.VUE_APP_WUWOW_PORTAL_URL}/event/regular_mgmevent_kid/?rc=${this.mgmInfo.recommend_code}&nt=${this.mgmInfo.bd.english_name}`;
      // this.defaultShareText = `---<br>【WUWOW 線上英文週年慶】年度下殺最優惠！<br>★ 參加活動即刻獲得「課程買一送一」優惠，還能抽「紐西蘭來回機票」。<br><br>➢別等了！即刻參加活動 >> ${linkUrl}<br><br>#WUWOW線上英文 #WUWOW線上英文週年慶`;
      // 暑期活動結束後打開
      // this.defaultShareText = `---<br>【WUWOW / WUWOW JUNIOR 新朋友加入好禮】學習更划算！<br>★ 透過好友推薦加入學習英文，再贈多堂免費英文課程！<br><br>➢別等了！即刻參加活動 >> ${linkUrl}<br><br>#WUWOW線上英文`;
      if (this.deadlinesAfter) {
        this.defaultShareText = `
        <br>-------
        <br>【WUWOW / WUWOW JUNIOR 新朋友加入好禮】學習更划算！
        <br> ★ 透過好友推薦加入學習英文，再贈多堂免費英文課程！
        <br>
        <br>➢別等了！即刻參加活動 >> ${linkUrl}
        <br>
        #WUWOW JUNIOR線上英文`;
      } else {
        this.defaultShareText = `
        －－－－
        <br>【WUWOW JUNIOR 線上英文週年慶】年度下殺最優惠！
        <br> ★ 免費英文健檢抽「不限航點補助來回機票」
        <br> ★ 買指定課程「限量買一送一」！
        <br>
        <br>➢別等了！即刻參加 >> ${linkUrl}
        <br>
        #WUWOW JUNIOR線上英文 #WUWOW線上英文週年慶`;
      }
      this.setMediaShareUrl(this.mgmInfo);
    },
    focusTextarea() {
      this.focusShareText = !this.focusShareText;
    },
    copyShareText() {
      this.copiedShare = true;
      const defaultShareText = this.defaultShareText.replace(
        new RegExp('<br>', 'g'),
        '\n'
      );
      const value = `${this.vipShareText}\n${defaultShareText}`;
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    copyUrl(){
      var copyText = document.getElementById('vipMgmUrl');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.copiedMS = true;
    },
    async saveShareText() {
      await LioshutanApi.vipMgm.editVipShareContents({
        content: this.vipShareText,
        recommendCode: this.mgmInfo.recommend_code,
      });
    },
    setMediaShareUrl(mgmInfo) {
      // 設定複製網址
      this.mgmRecommendUrl += '?rc=' + mgmInfo.recommend_code;
      // 設定分享網址
      this.vipMediaShareUrl.lineLink += '%3Frc%3D' + mgmInfo.recommend_code;
      this.vipMediaShareUrl.fbDataHref += '?rc=' + mgmInfo.recommend_code;
      this.vipMediaShareUrl.fbLink += '%3Frc%3D' + mgmInfo.recommend_code;
      // 有業務名稱才新增note
      if (mgmInfo.bd != null) {
        this.mgmRecommendUrl += '&nt=' + mgmInfo.bd.english_name;
        this.vipMediaShareUrl.lineLink += '%26nt%3D' + mgmInfo.bd.english_name;
        this.vipMediaShareUrl.fbDataHref += '&nt=' + mgmInfo.bd.english_name;
        this.vipMediaShareUrl.fbLink +=
          '%26nt%3D' + mgmInfo.bd.english_name + '&amp;src=sdkpreparse';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copyBtn {
  border: 1px solid #216DDB;
}
.saveBtn {
  background: #216DDB;
  color: #fff;
}
.customized-share-text {
  box-shadow: 0px 0px 8px 0px rgba(187, 198, 228, 0.30);
  border-radius: 10px;
  padding: 20px;
  h2 {
    text-align: center;
    color: #216DDB;
    font-weight: bold;
    font-size: 21px;
  }
}
.customized-share-text .share-text {
  // width: 100%;
  // height: 100px;
  border: 1px solid #216DDB;
  // border-radius: 5px;
  // padding: 10px;
  // resize: none;
}
</style>
