var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "recommended-record"
  }, [_c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "col": "",
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "recommendInfo-seven-anniversary",
    class: {
      'anniversary-2023': !_vm.deadlinesAfter
    }
  }, [!_vm.deadlinesAfter ? _c('div', {
    staticClass: "win-rate"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('winningRate')))]), _c('div', {
    staticClass: "text-white"
  }, [_c('span', {
    staticClass: "rate"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.winning_rate))]), _c('span', {
    staticClass: "text-white percent"
  }, [_vm._v("%")])])]) : _c('div', {
    staticClass: "win-rate"
  }, [_c('img', {
    staticClass: "iphone-img iphone-img-left",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-iphone1.svg",
      "alt": ""
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('winningRate')))]), _c('div', [_c('img', {
    staticClass: "draw-img",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-draw.svg",
      "alt": ""
    }
  }), _c('span', {
    staticClass: "rate"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.winning_rate))]), _c('span', {
    staticClass: "percent"
  }, [_vm._v("%")])]), _c('img', {
    staticClass: "iphone-img iphone-img-right",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-iphone2.svg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "circle"
  }, [_c('img', {
    staticClass: "ticket-img",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-ticket.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t('myTicket')))]), _c('p', [_c('span', {
    staticClass: "circle-count"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.get_ticket))]), _vm._v(_vm._s(_vm.$t('piece')))])])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "circle"
  }, [_c('img', {
    staticClass: "ticket-img",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-member.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t('currentPeriodTickets')))]), _c('p', [_c('span', {
    staticClass: "circle-count"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.current_period_tickets))]), _vm._v(_vm._s(_vm.$t('piece')))])])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "circle"
  }, [_c('img', {
    staticClass: "ticket-img",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-praise.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t('countReferrerMonth')))]), _c('p', [_c('span', {
    staticClass: "circle-count"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.count_referrer_month))]), _vm._v(" " + _vm._s(_vm.$t('people')))])])]), _c('div', {
    staticClass: "col-md-3 col-6"
  }, [_c('div', {
    staticClass: "circle"
  }, [_c('img', {
    staticClass: "ticket-img",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-hourglass.svg",
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t('countdownToDraw')))]), _c('p', [_c('span', {
    staticClass: "circle-count"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.countdown_to_draw))]), _vm._v(" " + _vm._s(_vm.$t('day')))])])])])]), _c('div', {
    staticClass: "info-botton"
  }, [_c('p', {
    staticClass: "text-white m-0"
  }, [_vm._v("已經有"), _c('span', {
    staticClass: "friend-count"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.count_referrer))]), _vm._v("位朋友加入 WUWOW JUNIOR"), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("一起學習")]), !_vm.deadlinesAfter && _vm.vipRecommendInfo.is_buy_google ? _c('p', {
    staticClass: "text-white m-0"
  }, [_vm._v("已經有"), _c('span', {
    staticClass: "friend-count"
  }, [_vm._v(_vm._s(_vm.vipRecommendInfo.count_google_referrer))]), _vm._v("位朋友加入 Google 專屬活動"), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("一起學習")]) : _vm._e()])])])], 1)], 1)], 1), !_vm.deadlinesAfter && _vm.vipRecommendInfo.is_buy_google ? _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "google-groupReport"
  }, [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/google-2023-groupReport.svg"
    }
  }), _c('input', {
    staticClass: "copyText",
    attrs: {
      "type": "text",
      "id": "copyTextId",
      "readonly": "readonly"
    },
    domProps: {
      "value": _vm.mgmRecommendGoogleUrl
    }
  }), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn copyBtn",
    on: {
      "click": function ($event) {
        return _vm.copyUrl();
      }
    }
  }, [_vm._v("複製")])])])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', [_c('customizedShareTextGoogle')], 1)])], 1) : _c('div', {
    staticClass: "shareContent mx-auto mt-5",
    staticStyle: {
      "width": "80%"
    }
  }, [_c('customizedShareText', {
    attrs: {
      "usePage": 'recommendedAnniversary'
    }
  })], 1), _c('b-row', [_c('div', {
    staticClass: "recordBtnGroup"
  }, _vm._l(_vm.recordBtnGroup, function (btn) {
    return _c('button', {
      key: btn.index,
      class: [_vm.recordType === btn.value ? 'recordBtnSelect' : ''],
      on: {
        "click": function ($event) {
          return _vm.changeType(btn.value);
        }
      }
    }, [_vm._v(_vm._s(btn.title))]);
  }), 0)]), _c('b-row', {
    staticClass: "justify-content-md-center recordFilterGroup"
  }, [_vm.dateType === _vm.vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE ? _c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('datepicker', {
    attrs: {
      "bootstrapstyling": true,
      "format": "yyyy-MM-dd",
      "placeholder": '開始日期',
      "disabled": _vm.startDateDisable
    },
    model: {
      value: _vm.recordListParams.cts,
      callback: function ($$v) {
        _vm.$set(_vm.recordListParams, "cts", $$v);
      },
      expression: "recordListParams.cts"
    }
  })], 1), _c('br', {
    staticClass: "m-br"
  }), _c('br', {
    staticClass: "m-br"
  }), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('datepicker', {
    attrs: {
      "bootstrapstyling": true,
      "format": "yyyy-MM-dd",
      "placeholder": '結束日期',
      "disabled": _vm.endDateDisable
    },
    model: {
      value: _vm.recordListParams.cte,
      callback: function ($$v) {
        _vm.$set(_vm.recordListParams, "cte", $$v);
      },
      expression: "recordListParams.cte"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.recordType !== _vm.vipMgmConstants.RECORD_TYPE.AWARD ? _c('b-col', [_c('b-row', [_c('b-col', {
    staticClass: "recordFilterFieldOrder",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.dateType === _vm.vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onSearch('firstPage');
      }
    }
  }, [_vm._v("查詢")]) : _vm._e()], 1), _c('br', {
    staticClass: "m-br"
  }), _c('br', {
    staticClass: "m-br"
  }), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-select', {
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    },
    model: {
      value: _vm.dateType,
      callback: function ($$v) {
        _vm.dateType = $$v;
      },
      expression: "dateType"
    }
  }, [_c('option', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.recordType === _vm.vipMgmConstants.RECORD_TYPE.RECOMMEND,
      expression: "recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND"
    }],
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.TODAY
    }
  }, [_vm._v("今天")]), _c('option', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.recordType === _vm.vipMgmConstants.RECORD_TYPE.RECOMMEND,
      expression: "recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND"
    }],
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.YESTERDY
    }
  }, [_vm._v("昨天")]), _c('option', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.recordType === _vm.vipMgmConstants.RECORD_TYPE.RECOMMEND,
      expression: "recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND"
    }],
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.PAST_SEVEN_DAYS
    }
  }, [_vm._v("過去七天")]), _c('option', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.recordType === _vm.vipMgmConstants.RECORD_TYPE.TICKET,
      expression: "recordType === vipMgmConstants.RECORD_TYPE.TICKET"
    }],
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.THIS_MONTH
    }
  }, [_vm._v("當月")]), _c('option', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.recordType === _vm.vipMgmConstants.RECORD_TYPE.TICKET,
      expression: "recordType === vipMgmConstants.RECORD_TYPE.TICKET"
    }],
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.LAST_MONTH
    }
  }, [_vm._v("上個月")]), _c('option', {
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE
    }
  }, [_vm._v("自訂日期")]), _c('option', {
    domProps: {
      "value": _vm.vipMgmConstants.DATE_TYPE.ALL
    }
  }, [_vm._v("查看全部")])])], 1)], 1)], 1) : _vm._e()], 1), _vm.recommendList !== null && _vm.recommendList.length !== 0 ? _c('div', {
    staticClass: "rewards-table"
  }, [_vm.recordType === 'award' ? [_vm._m(0)] : _vm._e(), _vm.recordType === 'ticket' ? [_vm._m(1)] : _vm._e(), _vm.recordType === 'recommend' ? [_vm._m(2)] : _vm._e(), _vm.recordType === 'award' ? _vm._l(_vm.recommendList, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "reward-detail award"
    }, [_c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("獎項成立時間")]), _c('p', [_vm._v(_vm._s(item.created_at))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("成交獎項")]), _c('p', [item.type == 'raffle' || item.type == 'activity' ? _c('b-badge', {
      attrs: {
        "pill": "",
        "variant": "warning"
      }
    }, [_vm._v(_vm._s(_vm.awardType(item.type)))]) : item.type == 'deal' ? _c('b-badge', {
      attrs: {
        "pill": "",
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(_vm.awardType(item.type)))]) : item.type == 'deal_bonus' ? _c('b-badge', {
      attrs: {
        "pill": "",
        "variant": "success"
      }
    }, [_vm._v(_vm._s(_vm.awardType(item.type)))]) : _vm._e(), _c('br'), _vm._v(_vm._s(item.prizes_config.prize_name))], 1)]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("推薦時間")]), _c('p', [_vm._v(_vm._s(_vm.changeMomentFormat(item.user_mgm_referee.created_at, 'YYYY-MM-DD')))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("領獎類型")]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.awardTypeValue(item))
      }
    })]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("領獎狀態")]), _c('p', [_vm._v(_vm._s(_vm.awardStatus(item.status)))])])]);
  }) : _vm._e(), _vm.recordType === 'ticket' ? _vm._l(_vm.recommendList, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "reward-detail ticket"
    }, [_c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("抽獎券號碼")]), _c('p', [_vm._v(_vm._s(item.ticket.code))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("獲得時間")]), _c('p', [_vm._v(_vm._s(item.created_at))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("被推薦者姓名")]), _c('p', [_vm._v("推薦朋友 " + _vm._s(item.referee_user.chinese_name))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("中獎狀態")]), _c('p', [_vm._v(_vm._s(_vm.ticketStatus(item.ticket)))])])]);
  }) : _vm._e(), _vm.recordType === 'recommend' ? _vm._l(_vm.recommendList, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "reward-detail ticket"
    }, [_c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("被推薦者姓名")]), _c('p', [_vm._v(_vm._s(item.chinese_name))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("推薦時間")]), _c('p', [_vm._v(_vm._s(item.created_at))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("被推薦者狀態")]), _c('p', [_vm._v(_vm._s(_vm.recommendVipStatus(item)))])]), _c('div', {
      staticClass: "reward-detail-item"
    }, [_c('h4', {
      staticClass: "reward-detail-title"
    }, [_vm._v("備註")]), item.note.main_display ? _c('b-badge', {
      staticClass: "p-2 popper-customer",
      attrs: {
        "tag": "button",
        "pill": "",
        "variant": "success"
      }
    }, [_vm._v(_vm._s(item.note.main_display)), _c('span', {
      staticClass: "exclamation-icon"
    }, [_vm._v("!")]), _c('span', {
      staticClass: "popper-customer-content"
    }, [_vm._v(_vm._s(item.note.display_detail))])]) : _vm._e()], 1)]);
  }) : _vm._e()], 2) : _c('div', {
    staticClass: "recordEmptyBox"
  }, [_c('b-row', [_vm.recordType == _vm.vipMgmConstants.RECORD_TYPE.RECOMMEND ? _c('b-col', [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/recommend-record.png",
      "alt": "推薦好友，享WUWOW Jr好禮呦！",
      "title": "推薦好友，享WUWOW Jr好禮呦！"
    }
  })]) : _vm.recordType == _vm.vipMgmConstants.RECORD_TYPE.TICKET ? _c('b-col', [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/ticket-record.png",
      "alt": "推薦好友，享WUWOW Jr好禮呦！",
      "title": "推薦好友，享WUWOW Jr好禮呦！"
    }
  })]) : _vm.recordType == _vm.vipMgmConstants.RECORD_TYPE.AWARD ? _c('b-col', [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/award-record.png",
      "alt": "推薦好友，享WUWOW Jr好禮呦！",
      "title": "推薦好友，享WUWOW Jr好禮呦！"
    }
  })]) : _vm._e()], 1), _c('b-row', [_c('b-col', [_c('p', [_vm._v("推薦好友，享WUWOW Jr好禮呦！")])])], 1)], 1)], 1), _vm.recommendList != null ? _c('div', {
    staticClass: "wuwow-card-footer"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.recommendList.total,
      "per-page": Number(_vm.recommendList.per_page)
    },
    on: {
      "input": function ($event) {
        return _vm.onSearch('changePage');
      }
    },
    model: {
      value: _vm.next_currentPage,
      callback: function ($$v) {
        _vm.next_currentPage = $$v;
      },
      expression: "next_currentPage"
    }
  })], 1) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reward-detail-header award"
  }, [_c('p', [_vm._v("獎項成立時間")]), _c('p', [_vm._v("成交獎項")]), _c('p', [_vm._v("推薦時間")]), _c('p', [_vm._v("領獎類型")]), _c('p', [_vm._v("領獎狀態")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reward-detail-header ticket"
  }, [_c('p', [_vm._v("抽獎券號碼")]), _c('p', [_vm._v("獲得時間")]), _c('p', [_vm._v("被推薦者姓名")]), _c('p', [_vm._v("中獎狀態")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reward-detail-header recommend"
  }, [_c('p', [_vm._v("被推薦者姓名")]), _c('p', [_vm._v("推薦時間")]), _c('p', [_vm._v("被推薦者狀態")]), _c('p', [_vm._v("備註")])]);

}]

export { render, staticRenderFns }