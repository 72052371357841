<template lang="pug">
#gift-step2
  b-container.gift-step2-wrap(v-if="deadlinesAfter")
    b-row.title-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col
        .text-center
          h3(style='margin-bottom: 5px;') 第二重好禮
          h1 🌟成功推薦wu獎金🌟
    b-row(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col
        .text-center.row-margin
          p
            | 除了「幸運抽獎許願池」可以試試手氣，
            br.pc-br
            | 凡是親友經由你的推薦，成為 WUWOW / WUWOW JUNIOR 學員，
            br.pc-br
            | 依購買方案類型，你與親友都能獲贈不同的推薦獎金或課程堂數。
    b-row.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col
        .text-center.row-margin
          h4.text-center
            span.highlight 推薦無上限，豐厚獎金看這邊！
          p
            | 推薦人數無上限，「指定方案」推薦人單次最高可領取
            br.pc-br
            span.highlight $6,000 推薦獎金
            | （可兌換等值課程堂數或延長會籍期限）；
            br.pc-br
            | 被推薦人最高可額外獲得 45堂 WUWOW 課程，或 15堂
            br.pc-br
            | WUWOW JUNIOR 課程。
    b-row.row-margin(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
      b-col(cols='8' offset="2" md='6' lg="4" offset-md="0" offset-lg="2" class="mb-4 mb-xl-0")
        div
          img.img-fluid(:src="require('@/assets/mgm/summerCamp-method1.png')" alt="method1")
      b-col(cols='8' offset="2" md='6' lg="4" offset-md="0")
        div
          img.img-fluid(:src="require('@/assets/mgm/summerCamp-method2.png')" alt="method2")
    .rightNowBox.text-center(data-aos='fade-up' data-aos-duration='1500' data-aos-delay='500' data-aos-once='true')
      h4 你還在等什麼？
      #rightNowBtn2.rightNowBtn(data-aos='zoom-in' data-aos-duration='500' data-aos-delay='1000' data-aos-once='true')
        a(:href='mgmRecommendUrl' target='_blank')
          span 立刻開揪
      p 快與朋友一起體驗最有效的線上英文學習囉！
    slot
  b-container(v-else)
    b-row.justify-content-center.gift-step2-wrap
      b-col(cols='12' md="10")
        .anniversary_2023
          h2.text-center
            span 第二重好禮
            br
            | 🌟成功推薦 WU獎金🌟
          .anniversary_2023_content
            p.descriptive
              | 除了「幸運抽獎許願池」可以試試手氣，
              br
              | 凡是親友經由你的推薦，成為 WUWOW / WUWOW JUNIOR 學員，
              br
              | 依購買方案類型，你與親友都能獲贈不同的推薦獎金或課程堂數。
            .recommend
              h3.highlight 推薦無上限，豐厚獎金看這邊！
              p
                | 推薦人數無上限，「指定方案」推薦人單次最高可領取
                br
                span.highlight $6,500 推薦獎金
                | （可兌換等值課程堂數或延長會籍期限）；
                br
                | 被推薦人最高可額外獲得 45堂 WUWOW 課程，或 15堂
                br
                | WUWOW JUNIOR 課程。
            .recommend-images
              img(src="https://cdn2.wuwow.tw/pictures/mgms/gift-2023-Introduce-cash.svg")
              img(src="@/assets/mgm/summerCamp-method2.png")
    slot
</template>

<script type="text/javascript">
import LearnRuleModal from '@/components/wushare/learnRuleModal';
import moment from 'moment';
export default {
  name: 'GiftContentTwo',

  components: {
    LearnRuleModal,
  },

  props: {
    currentAwardList: {
      type: Object,
      default: function() {
        return {};
      },
    },
    mgmRecommendUrl: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
      deadlines: '2024-01-08 00:00:00',
      deadlinesAfter: false,
      giftImgGroup: [],
      firstAward: '',
      secondAward: '',
      thirdAward: '',
      fourthAward: '',
    };
  },

  computed: {},

  watch: {},

  mounted(){
    this.setAwardData();
    this.giftImgGroup = this.currentAwardList.deals;
  },

  created() {
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },

  methods: {
    setAwardData(){
      this.firstAward = this.currentAwardList.deals[0].prize_name;
      this.secondAward = this.currentAwardList.deals[1].prize_name;
      this.thirdAward = this.currentAwardList.deals[2].prize_name;
      this.fourthAward = this.currentAwardList.deals[3].prize_name;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
    @media (min-width: 475px) {
      @content;
    }
}

@mixin md {
    @media (min-width: 768px) {
      @content;
    }
}
@mixin lg {
    @media (min-width: 1024px) {
      @content;
    }
}

@mixin xl {
    @media (min-width: 1240px) {
      @content;
    }
}
@mixin xxl {
    @media (min-width: 1680px) {
      @content;
    }
}

@mixin xxxl {
    @media (min-width: 1920px) {
      @content;
    }
}
.anniversary_2023 {
	border-radius: 20px 20px 0px 0px;
	border: 6px solid #F7F0D9;

	h2 {
		background: #F7F0D9;
		font-size: 16px;
		text-align: center;
		color: #151B1E;
		font-weight: 700;
		margin-bottom: 0;
		padding: 12px 0;
		@include xl {
			font-size: 32px;
		}
		span {
			display: inline-block;
			font-size: 14px;
			padding-bottom: 4px;
			@include xl {
				font-size: 21px;
			}
		}
	}
}
.anniversary_2023_content {
  padding: 20px 10px;
	@include md {
		padding: 20px 40px;
	}
  .descriptive {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin: 0 0 76px 0;
  }
  .recommend {
    text-align: center;
    h3 {
      font-size: 21px;
      color: #151B1E;
      display: inline-block;
    }
    p {
      margin: 0;
      span {
        font-weight: 700;
      }
    }
  }
}
.recommend-images {
  text-align: center;
	@include lg {
		display: flex;
		justify-content: space-between;
	}
	img {
    width: 100%;
		margin-bottom: 10px;
		padding: 0 12px;
		@include lg {
			width: 50%;
			margin-bottom: 0;
		}
		// @include md {
		// }
	}
}
.gift-step2-wrap {
  margin-bottom: 20px;
}
</style>
