<template lang="pug">
div
  vipMgm(:vipRecommendInfo='vipRecommendInfo')
</template>

<script type="text/javascript">
import vipMgm from '@/components/vipMgm';
import LioshutanApi from '@/api';

export default {
  name: 'VipMgmView',

  components: {
    vipMgm,
  },

  data() {
    return {
      vipRecommendInfo: {}, // 推薦方法
    };
  },

  computed: {},

  watch: {},

  created() {
    this.getVipMgmData();
  },
  methods: {
    async getVipMgmData(){
      const result = await LioshutanApi.vipMgm.getVipRecommendInfo();
      this.vipRecommendInfo = result.data.data.mgm_info;
    },
  },
};
</script>

<style lang="scss">
@import './vipMgm';
@import '@/styles/transition';
</style>
