var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('b-container', [_c('b-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "500",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', [_c('picture', [_c('source', {
    attrs: {
      "srcset": "https://cdn2.wuwow.tw/pictures/mgms/recommend-method-title-mobile.png",
      "media": "(max-width: 768px)",
      "alt": "推薦方法標題",
      "title": "推薦方法標題"
    }
  }), _c('img', {
    staticClass: "img-fluid",
    attrs: {
      "srcset": "https://cdn2.wuwow.tw/pictures/mgms/recommend-method-title-pc.png",
      "alt": "推薦方法標題",
      "title": "推薦方法標題"
    }
  })])])])], 1), _c('br'), _c('br'), _c('b-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "1500",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "recommendContentPC text-center"
  }, [_c('p', [_vm._v("你身邊也有想學英文的親朋好友嗎？"), _c('br'), _vm._v("不論你是 WUWOW 還是 WUWOW Jr 學員"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("現在推薦他們一起學英文，被推薦的親友就可以"), _c('br'), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("獲贈額外課程堂數！")]), _c('br'), _c('br'), _vm._v("熱心推薦的你更可以獲得"), _c('span', {
    staticClass: "highlight"
  }, [_vm._v(" 優渥的推薦獎金，")])])])])], 1), _c('b-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "1600",
      "data-aos-once": "true"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "anniversary-2023-end"
  }, [_c('div', {
    staticClass: "anniversary-2023-end-main"
  }, [_c('h2', [_vm._v("週年慶加碼活動結束囉")]), _c('p', [_vm._v("LINE POINTS 200 點"), _c('br'), _vm._v("將會於"), _c('span', [_vm._v("2024/1/31")]), _vm._v("日進行發放"), _c('br'), _vm._v("相關推薦紀錄請至「"), _c('span', [_vm._v("我的推薦")]), _vm._v("」查詢")]), _c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/getPoint-left.png",
      "alt": ""
    }
  }), _c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/getPoint-right.png",
      "alt": ""
    }
  })])])])], 1), _c('b-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "1600",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "anniversary-tips mt-2 mb-5 text-center"
  }, [_c('span', {
    staticClass: "position-relative"
  }, [_vm._v("快去寫下你的推薦心得，一鍵複製給親朋好友"), _c('br'), _vm._v("讓更多人一起同樂，還能提升你的中獎率哦！"), _c('img', {
    staticClass: "position-absolute",
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-tips-icon.png",
      "alt": ""
    }
  })])])])], 1), _c('b-row', {
    staticClass: "giftBtnGroup",
    attrs: {
      "align-h": "center",
      "data-aos": "fade-up",
      "data-aos-duration": "500",
      "data-aos-delay": "500",
      "data-aos-once": "true"
    }
  }, _vm._l(_vm.giftBtns, function (gift, index) {
    return _c('b-col', {
      key: `giftBtn_${index}`,
      staticClass: "mb-5 mb-md-0 px-2",
      attrs: {
        "lg": "4",
        "md": "4",
        "cols": "12"
      }
    }, [_c('a', [_c('div', {
      staticClass: "giftBtn anniversary_2023",
      attrs: {
        "id": 'gift_btn' + gift.index
      },
      on: {
        "click": function ($event) {
          _vm.giftContent = gift.index, _vm.scrollToGift();
        }
      }
    }, [_c('p', [_vm._v("好禮"), _c('span', {
      staticClass: "anniversary-title"
    }, [_vm._v(_vm._s(gift.title))]), _c('br')]), _c('div', {
      staticClass: "gifts",
      domProps: {
        "innerHTML": _vm._s(gift.content)
      }
    }), _c('p'), _c('p', {
      staticClass: "btn-read"
    }, [_vm._v("立即了解")]), _c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": gift.imgUrl,
        "alt": ""
      }
    })])])]);
  }), 1)], 1), _c('div', {
    attrs: {
      "id": "gift-content"
    }
  }, [_vm.giftContent === 1 ? _c('gift-content-one', {
    attrs: {
      "currentAwardList": _vm.prizesConfig,
      "mgmRecommendUrl": _vm.mgmRecommendUrl
    }
  }) : _vm._e(), _vm.giftContent === 2 ? _c('gift-content-two', {
    attrs: {
      "currentAwardList": _vm.prizesConfig,
      "mgmRecommendUrl": _vm.mgmRecommendUrl
    }
  }) : _vm._e()], 1)], 1), _c('recommend-step'), _c('b-img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/mgm-footer.png",
      "fluid": "",
      "alt": "頁尾圖片",
      "title": "頁尾圖片"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }