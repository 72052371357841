var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "gift-step2"
    }
  }, [_vm.deadlinesAfter ? _c('b-container', {
    staticClass: "gift-step2-wrap"
  }, [_c('b-row', {
    staticClass: "title-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v("第二重好禮")]), _c('h1', [_vm._v("🌟成功推薦wu獎金🌟")])])])], 1), _c('b-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "text-center row-margin"
  }, [_c('p', [_vm._v("除了「幸運抽獎許願池」可以試試手氣，"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("凡是親友經由你的推薦，成為 WUWOW / WUWOW JUNIOR 學員，"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("依購買方案類型，你與親友都能獲贈不同的推薦獎金或課程堂數。")])])])], 1), _c('b-row', {
    staticClass: "row-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', [_c('div', {
    staticClass: "text-center row-margin"
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "highlight"
  }, [_vm._v("推薦無上限，豐厚獎金看這邊！")])]), _c('p', [_vm._v("推薦人數無上限，「指定方案」推薦人單次最高可領取"), _c('br', {
    staticClass: "pc-br"
  }), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("$6,000 推薦獎金")]), _vm._v("（可兌換等值課程堂數或延長會籍期限）；"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("被推薦人最高可額外獲得 45堂 WUWOW 課程，或 15堂"), _c('br', {
    staticClass: "pc-br"
  }), _vm._v("WUWOW JUNIOR 課程。")])])])], 1), _c('b-row', {
    staticClass: "row-margin",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', {
    staticClass: "mb-4 mb-xl-0",
    attrs: {
      "cols": "8",
      "offset": "2",
      "md": "6",
      "lg": "4",
      "offset-md": "0",
      "offset-lg": "2"
    }
  }, [_c('div', [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require('@/assets/mgm/summerCamp-method1.png'),
      "alt": "method1"
    }
  })])]), _c('b-col', {
    attrs: {
      "cols": "8",
      "offset": "2",
      "md": "6",
      "lg": "4",
      "offset-md": "0"
    }
  }, [_c('div', [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require('@/assets/mgm/summerCamp-method2.png'),
      "alt": "method2"
    }
  })])])], 1), _c('div', {
    staticClass: "rightNowBox text-center",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1500",
      "data-aos-delay": "500",
      "data-aos-once": "true"
    }
  }, [_c('h4', [_vm._v("你還在等什麼？")]), _c('div', {
    staticClass: "rightNowBtn",
    attrs: {
      "id": "rightNowBtn2",
      "data-aos": "zoom-in",
      "data-aos-duration": "500",
      "data-aos-delay": "1000",
      "data-aos-once": "true"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.mgmRecommendUrl,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v("立刻開揪")])])]), _c('p', [_vm._v("快與朋友一起體驗最有效的線上英文學習囉！")])]), _vm._t("default")], 2) : _c('b-container', [_c('b-row', {
    staticClass: "justify-content-center gift-step2-wrap"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('div', {
    staticClass: "anniversary_2023"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_c('span', [_vm._v("第二重好禮")]), _c('br'), _vm._v("🌟成功推薦 WU獎金🌟")]), _c('div', {
    staticClass: "anniversary_2023_content"
  }, [_c('p', {
    staticClass: "descriptive"
  }, [_vm._v("除了「幸運抽獎許願池」可以試試手氣，"), _c('br'), _vm._v("凡是親友經由你的推薦，成為 WUWOW / WUWOW JUNIOR 學員，"), _c('br'), _vm._v("依購買方案類型，你與親友都能獲贈不同的推薦獎金或課程堂數。")]), _c('div', {
    staticClass: "recommend"
  }, [_c('h3', {
    staticClass: "highlight"
  }, [_vm._v("推薦無上限，豐厚獎金看這邊！")]), _c('p', [_vm._v("推薦人數無上限，「指定方案」推薦人單次最高可領取"), _c('br'), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("$6,500 推薦獎金")]), _vm._v("（可兌換等值課程堂數或延長會籍期限）；"), _c('br'), _vm._v("被推薦人最高可額外獲得 45堂 WUWOW 課程，或 15堂"), _c('br'), _vm._v("WUWOW JUNIOR 課程。")])]), _c('div', {
    staticClass: "recommend-images"
  }, [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/pictures/mgms/gift-2023-Introduce-cash.svg"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/mgm/summerCamp-method2.png")
    }
  })])])])])], 1), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }