<template lang="pug">
.recommended-record
  .wuwow-card
    .wuwow-card-body
      b-row
        b-col(col='' lg='12')
          b-row
            b-col
              .recommendInfo-seven-anniversary(:class="{'anniversary-2023': !deadlinesAfter }")
                //- 新畫面
                .win-rate(v-if="!deadlinesAfter")
                  //- img.iphone15(src='https://cdn2.wuwow.tw/pictures/mgms/phone15.svg' alt='')
                  div {{$t('winningRate')}}
                  div.text-white
                    //- img.draw-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-draw.svg' alt='')
                    span.rate {{vipRecommendInfo.winning_rate}}
                    span.text-white.percent %
                  //- img.airplane(src='https://cdn2.wuwow.tw/pictures/mgms/airplane.svg' alt='')
                //- 舊畫面
                .win-rate(v-else)
                  img.iphone-img.iphone-img-left(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-iphone1.svg' alt='')
                  div {{$t('winningRate')}}
                  div
                    img.draw-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-draw.svg' alt='')
                    span.rate {{vipRecommendInfo.winning_rate}}
                    span.percent %
                  img.iphone-img.iphone-img-right(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-iphone2.svg' alt='')
                .info
                  .row
                    .col-md-3.col-6
                      .circle
                        img.ticket-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-ticket.svg' alt='')
                        p {{$t('myTicket')}}
                        p
                          span.circle-count {{vipRecommendInfo.get_ticket}}
                          | {{$t('piece')}}
                    .col-md-3.col-6
                      .circle
                        img.ticket-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-member.svg' alt='')
                        p {{$t('currentPeriodTickets')}}
                        p
                          span.circle-count {{vipRecommendInfo.current_period_tickets}}
                          | {{$t('piece')}}
                    .col-md-3.col-6
                      .circle
                        img.ticket-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-praise.svg' alt='')
                        p {{$t('countReferrerMonth')}}
                        p
                          span.circle-count {{vipRecommendInfo.count_referrer_month}}
                          |  {{$t('people')}}
                    .col-md-3.col-6
                      .circle
                        img.ticket-img(src='https://cdn2.wuwow.tw/pictures/mgms/anniversary-hourglass.svg' alt='')
                        p {{$t('countdownToDraw')}}
                        p
                          span.circle-count {{vipRecommendInfo.countdown_to_draw}}
                          |  {{$t('day')}}
                .info-botton
                  p.text-white.m-0
                    | 已經有
                    span.friend-count {{vipRecommendInfo.count_referrer}}
                    | 位朋友加入 WUWOW JUNIOR
                    br.d-md-none
                    | 一起學習
                  p.text-white.m-0(v-if="!deadlinesAfter && vipRecommendInfo.is_buy_google")
                    | 已經有
                    span.friend-count {{vipRecommendInfo.count_google_referrer}}
                    | 位朋友加入 Google 專屬活動
                    br.d-md-none
                    |一起學習
      b-row.mt-4(v-if="!deadlinesAfter && vipRecommendInfo.is_buy_google")
        b-col(cols='12' md='6')
          .google-groupReport
            img.mb-4(src="https://cdn2.wuwow.tw/pictures/mgms/google-2023-groupReport.svg")
            input.copyText(type="text" id="copyTextId" :value="mgmRecommendGoogleUrl" readonly="readonly")
            .text-right
              button.btn.copyBtn(@click="copyUrl()") 複製
        b-col(cols='12' md='6')
          div
            customizedShareTextGoogle
      .shareContent.mx-auto.mt-5(style="width:80%;" v-else)
        customizedShareText(:usePage="'recommendedAnniversary'")
      b-row
        .recordBtnGroup
          button(v-for='btn in recordBtnGroup' :key='btn.index' :class="[recordType === btn.value ? 'recordBtnSelect':'']" @click='changeType(btn.value)') {{btn.title}}
      b-row.justify-content-md-center.recordFilterGroup
        b-col(v-if='dateType === vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE')
          b-row
            b-col(cols='12' md='6')
              datepicker(v-model='recordListParams.cts' :bootstrapstyling='true' format='yyyy-MM-dd' :placeholder="'開始日期'" :disabled='startDateDisable')
            br.m-br
            br.m-br
            b-col(cols='12' md='6')
              datepicker(v-model='recordListParams.cte' :bootstrapstyling='true' format='yyyy-MM-dd' :placeholder="'結束日期'" :disabled='endDateDisable')
        b-col(v-if="recordType !== vipMgmConstants.RECORD_TYPE.AWARD")
          b-row
            b-col.recordFilterFieldOrder(cols='12' md='6')
              b-button(v-if='dateType === vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE' @click="onSearch('firstPage')" variant='primary') 查詢
            br.m-br
            br.m-br
            b-col(cols='12' md='6')
              //-  下面要新增 @change='onChange($event)'
              b-form-select(v-model='dateType' @change='onChange($event)')
                option(v-show="recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND" :value='vipMgmConstants.DATE_TYPE.TODAY') 今天
                option(v-show="recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND" :value='vipMgmConstants.DATE_TYPE.YESTERDY') 昨天
                option(v-show="recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND" :value='vipMgmConstants.DATE_TYPE.PAST_SEVEN_DAYS') 過去七天
                option(v-show="recordType === vipMgmConstants.RECORD_TYPE.TICKET" :value='vipMgmConstants.DATE_TYPE.THIS_MONTH') 當月
                option(v-show="recordType === vipMgmConstants.RECORD_TYPE.TICKET" :value='vipMgmConstants.DATE_TYPE.LAST_MONTH') 上個月
                option(:value='vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE') 自訂日期
                option(:value='vipMgmConstants.DATE_TYPE.ALL') 查看全部
          //- template(v-if="recordType === 'award'")
      .rewards-table(v-if="recommendList !== null && recommendList.length !== 0")
        template(v-if="recordType === 'award'")
          .reward-detail-header.award
            p 獎項成立時間
            p 成交獎項
            p 推薦時間
            p 領獎類型
            p 領獎狀態
        template(v-if="recordType === 'ticket'")
          .reward-detail-header.ticket
            p 抽獎券號碼
            p 獲得時間
            p 被推薦者姓名
            p 中獎狀態
        template(v-if="recordType === 'recommend'")
          .reward-detail-header.recommend
            p 被推薦者姓名
            p 推薦時間
            p 被推薦者狀態
            p 備註
        template(v-if="recordType === 'award'")
          .reward-detail.award(v-for='(item, index) in recommendList' :key='item.id')
            .reward-detail-item
              h4.reward-detail-title 獎項成立時間
              p {{ item.created_at }}
            .reward-detail-item
              h4.reward-detail-title 成交獎項
              p
                b-badge(v-if="item.type=='raffle' || item.type=='activity'" pill='' variant='warning') {{ awardType(item.type) }}
                b-badge(v-else-if="item.type=='deal'" pill='' variant='primary') {{ awardType(item.type) }}
                b-badge(v-else-if="item.type=='deal_bonus'" pill='' variant='success') {{ awardType(item.type) }}
                br
                | {{ item.prizes_config.prize_name }}
            .reward-detail-item
              h4.reward-detail-title 推薦時間
              p {{ changeMomentFormat(item.user_mgm_referee.created_at, 'YYYY-MM-DD') }}
            .reward-detail-item
              h4.reward-detail-title 領獎類型
              p(v-html='awardTypeValue(item)')
            .reward-detail-item
              h4.reward-detail-title 領獎狀態
              p {{ awardStatus(item.status) }}
        template(v-if="recordType === 'ticket'")
          .reward-detail.ticket(v-for='(item, index) in recommendList' :key='item.id')
            .reward-detail-item
              h4.reward-detail-title 抽獎券號碼
              p {{ item.ticket.code }}
            .reward-detail-item
              h4.reward-detail-title 獲得時間
              p {{item.created_at}}
            .reward-detail-item
              h4.reward-detail-title 被推薦者姓名
              p 推薦朋友 {{ item.referee_user.chinese_name }}
            .reward-detail-item
              h4.reward-detail-title 中獎狀態
              p {{ ticketStatus(item.ticket) }}
        template(v-if="recordType === 'recommend'")
          .reward-detail.ticket(v-for='(item, index) in recommendList' :key='item.id')
            .reward-detail-item
              h4.reward-detail-title 被推薦者姓名
              p {{ item.chinese_name }}
            .reward-detail-item
              h4.reward-detail-title 推薦時間
              p {{item.created_at}}
            .reward-detail-item
              h4.reward-detail-title 被推薦者狀態
              p {{ recommendVipStatus(item) }}
            .reward-detail-item
              h4.reward-detail-title 備註
              //- p {{ item.note ? item.note.main_display : '' }}
              b-badge(v-if="item.note.main_display" tag="button" class="p-2 popper-customer" pill variant="success") {{ item.note.main_display }}
                span.exclamation-icon !
                span.popper-customer-content {{ item.note.display_detail }}
      .recordEmptyBox(v-else)
        b-row
          b-col(v-if="recordType==vipMgmConstants.RECORD_TYPE.RECOMMEND")
            img(src='https://cdn2.wuwow.tw/pictures/mgms/recommend-record.png' alt='推薦好友，享WUWOW Jr好禮呦！' title='推薦好友，享WUWOW Jr好禮呦！')
          b-col(v-else-if="recordType==vipMgmConstants.RECORD_TYPE.TICKET")
            img(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-record.png' alt='推薦好友，享WUWOW Jr好禮呦！' title='推薦好友，享WUWOW Jr好禮呦！')
          b-col(v-else-if="recordType==vipMgmConstants.RECORD_TYPE.AWARD")
            img(src='https://cdn2.wuwow.tw/pictures/mgms/award-record.png' alt='推薦好友，享WUWOW Jr好禮呦！' title='推薦好友，享WUWOW Jr好禮呦！')
        b-row
          b-col
            p 推薦好友，享WUWOW Jr好禮呦！
      //- b-row(v-if="false")
      //-   table.table.table-clsapc-record(v-if='recommendList != null')
          //- TODO: 待後續加上
          //- div(v-show="recordType === vipMgmConstants.RECORD_TYPE.TICKET && vipRecommendList.length > 5")
          //-   b-button.pull-right(v-if='!hideAllTicketInfoBtn' @click='showAllTicketInfo' variant='primary') 展開抽獎券資訊
          //-   b-button.pull-right(v-else-if='hideAllTicketInfoBtn' @click='hideAllTicketInfo' variant='primary') 收起抽獎券資訊
          //-   br
          //-   br
          //- thead(v-if="recordType=='recommend'")
          //-   tr
          //-     th 被推薦者姓名
          //-     th 推薦時間
          //-     th 被推薦者狀態
          //-     th 備註
          //- tbody
          //-   tr(v-for='(item, index) in recommendList' :key='item.id')
          //-     //- // 推薦紀錄
          //-     td(v-show="recordType=='recommend'") {{ item.chinese_name }}
          //-     td(v-show="recordType=='recommend'") {{ item.created_at }}
          //-     td(v-show="recordType=='recommend'") {{ recommendVipStatus(item) }}
          //-     td(v-if="recordType=='recommend'") {{ item.note ? item.note.main_display : '' }}
          //-     //- // 抽獎券紀錄
          //-     div(v-if="recordType === vipMgmConstants.RECORD_TYPE.TICKET")
          //-       .ticketTableHd(@click='showTicketInfo(index)')
          //-         div 抽獎券 {{ item.ticket_amount }} 張 / {{item.created_at}}
          //-         div 被推薦者姓名
          //-         div 中獎狀態
          //-       div(ref='ticketTable' v-if='clickNumber[index]')
          //-         .ticketTableBd(v-for='ticket in item.ticket_details' :key='ticket.code')
          //-           b-row
          //-             b-col(md='4') {{ ticket.code }}
          //-             b-col(md='4') 推薦朋友 {{ item.referee_user.chinese_name }}
          //-             b-col(md='4') {{ ticketStatus(ticket) }}
                      //- TODO: 待與後端討論是否可以後端傳該狀態 {{ ticketStatus(ticket) }}
              //- // 獎品紀錄
              //- .ticketTableBd(v-if="recordType === vipMgmConstants.RECORD_TYPE.AWARD")
              //-   .ticketTableHdRecord
              //-     div 獎項成立時間
              //-     div 成交獎項
              //-     div 推薦時間
              //-     div 領獎類型
              //-     div 領獎狀態
              //-   b-row
              //-     b-col.mt-1(md='2' style='line-height: 40px;padding-left: 6%;') {{ item.created_at }}
              //-     b-col.mt-1(md='3')
              //-       b-badge(v-if="item.type=='raffle' || item.type=='activity'" pill='' variant='warning') {{ awardType(item.type) }}
              //-       b-badge(v-else-if="item.type=='deal'" pill='' variant='primary') {{ awardType(item.type) }}
              //-       b-badge(v-else-if="item.type=='deal_bonus'" pill='' variant='success') {{ awardType(item.type) }}
              //-       br
              //-       |  {{ item.prizes_config.prize_name }}
              //-     b-col.mt-1(md='3' style='line-height: 40px;') {{ item.user_mgm_referee.created_at }}
              //-     b-col.mt-1(md='2' style='line-height: 40px;padding-right: 6%;' v-html='awardTypeValue(item)')
              //-     b-col.mt-1(md='2' style='line-height: 40px; padding-right: 7%;') {{ awardStatus(item.status) }}
                  //- 原本是要加入電子簽名去領獎
                  //- b-col(md='2' style='line-height: 40px; float:left; display:none;')
                  //-   b-button(variant='primary' @click='setWinnerInfoModal(item.id)' :disabled="item.status !== 'not_received'")
                  //-     | +
                  //-     i.far.fa-address-card
        //- .recordEmptyBox(v-else)
        //-   b-row
        //-     b-col(v-if="recordType==vipMgmConstants.RECORD_TYPE.RECOMMEND")
        //-       img(src='https://cdn2.wuwow.tw/pictures/mgms/recommend-record.png' alt='推薦好友，享WUWOW Jr好禮呦！' title='推薦好友，享WUWOW Jr好禮呦！')
        //-     b-col(v-else-if="recordType==vipMgmConstants.RECORD_TYPE.TICKET")
        //-       img(src='https://cdn2.wuwow.tw/pictures/mgms/ticket-record.png' alt='推薦好友，享WUWOW Jr好禮呦！' title='推薦好友，享WUWOW Jr好禮呦！')
        //-     b-col(v-else-if="recordType==vipMgmConstants.RECORD_TYPE.AWARD")
        //-       img(src='https://cdn2.wuwow.tw/pictures/mgms/award-record.png' alt='推薦好友，享WUWOW Jr好禮呦！' title='推薦好友，享WUWOW Jr好禮呦！')
        //-   b-row
        //-     b-col
        //-       p 推薦好友，享WUWOW Jr好禮呦！
    .wuwow-card-footer(v-if='recommendList != null')
      b-pagination(align='center' :total-rows='recommendList.total' v-model='next_currentPage' :per-page='Number(recommendList.per_page)' @input="onSearch('changePage')")
</template>
<script type="text/javascript">
import moment from 'moment';
import lioshutanApi from '@/api';
import { vipMgmLang } from '@/components/vipMgm/lang';
import vipMgmConstants from '@/constants/vipMgm.js';
import datepicker from 'vuejs-datepicker';
import customizedShareText from '@/components/vipMgm/customizedShareText/index.vue';
import customizedShareTextGoogle from '@/components/vipMgm/customizedShareTextGoogle/index.vue';

export default {
  name: 'RecommendedRecords',
  components: {
    datepicker,
    customizedShareText,
    customizedShareTextGoogle,
  },
  i18n: vipMgmLang,

  props: {
    vipRecommendInfo: { // 推薦方法
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      // recommendList: null,
      deadlines: '2024-01-08 00:00:00',
      deadlinesAfter: false,
      mgmRecommendGoogleUrl: `${process.env.VUE_APP_WUWOW_PORTAL_URL}event/regular_mgmevent_google/`,
      next_currentPage: 1,
      hideAllTicketInfoBtn: false,
      recordType: vipMgmConstants.RECORD_TYPE.RECOMMEND,
      dateType: vipMgmConstants.DATE_TYPE.TODAY,
      recordBtnGroup: [{
        title: '推薦紀錄',
        value: vipMgmConstants.RECORD_TYPE.RECOMMEND,
        index: 1,
      }, {
        title: '抽獎券紀錄',
        value: vipMgmConstants.RECORD_TYPE.TICKET,
        index: 2,
      }, {
        title: '獎勵紀錄',
        value: vipMgmConstants.RECORD_TYPE.AWARD,
        index: 3,
      }],
      // recommendList: [],
      clickNumber: [],
      recordListParams: {
        cts: '',
        cte: '',
      },
      // vipRecommendList: {},
      // vipRecommendInfo: {},
      vipRecommendList: {},
      vipTicketList: {},
      vipAwardList: {},
      vipMgmConstants: vipMgmConstants,
    };
  },

  computed: {
    startDateDisable: function() {
      if (this.recordListParams.cte !== '') {
        return {
          from: this.recordListParams.cte,
        };
      }

      return {};
    },

    endDateDisable: function() {
      if (this.recordListParams.cts !== '') {
        return {
          to: this.recordListParams.cts,
        };
      }

      return {};
    },
    dateRange: function() {
      // TODO: 後續調整至value裡 不要放在computed
      // 設定傳給api的日期區間（使用者設定篩選範圍時
      let result = {};
      const now = moment();
      const yesterday = moment().subtract(1, 'day');
      const start_date = moment().subtract(7, 'day');
      const last_month_date = moment().subtract(1, 'month');
      const last_month_year = last_month_date.format('YYYY');
      const last_month = parseInt(moment().format('MM')) - 1;
      const last_first_date = last_month_date.format('YYYY-MM') + '-01';
      const this_year = moment().format('YYYY');
      const this_month = parseInt(moment().format('MM')) - 1;
      const this_first_date = moment().format('YYYY-MM') + '-01';
      switch (this.dateType) {
        case vipMgmConstants.DATE_TYPE.ALL: // show all
          result = {};
          break;
        case vipMgmConstants.DATE_TYPE.TODAY: // today
          result = {
            cts: now.format('YYYY-MM-DD'),
            cte: now.format('YYYY-MM-DD'),
          };
          break;
        case vipMgmConstants.DATE_TYPE.YESTERDY: // yesterday
          result = {
            cts: yesterday.format('YYYY-MM-DD'),
            cte: yesterday.format('YYYY-MM-DD'),
          };
          break;
        case vipMgmConstants.DATE_TYPE.PAST_SEVEN_DAYS: // the past seven days
          result = {
            cts: start_date.format('YYYY-MM-DD'),
            cte: now.format('YYYY-MM-DD'),
          };
          break;
        case vipMgmConstants.DATE_TYPE.THIS_MONTH: // this month
          result = {
            cts: this_first_date,
            cte: moment([this_year, 0, 31]).month(this_month).format('YYYY-MM-DD'),
          };
          break;
        case vipMgmConstants.DATE_TYPE.LAST_MONTH: // last month
          result = {
            cts: last_first_date,
            cte: moment([last_month_year, 0, 31]).month(last_month).format('YYYY-MM-DD'),
          };
          break;
        case vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE: // custom
          result = {
            cts: moment(this.recordListParams.cts).format('YYYY-MM-DD'),
            cte: moment(this.recordListParams.cte).format('YYYY-MM-DD'),
          };
          break;
        default:
          break;
      }
      return result;
    },
    recommendList: function() {
      try {
        switch (this.recordType) {
          case 'recommend':
            if (this.vipRecommendList.length === 0) {
              return null;
            }
            return this.vipRecommendList;
          case 'ticket':
            if (this.vipTicketList.length === 0) {
              return null;
            }
            return this.vipTicketList.reduce((pre, cur) => {
              if (cur.ticket_details.length !== 0) {
                cur.ticket_details.forEach((detail) => {
                  pre.push({ ...cur, ticket: detail });
                });
              }
              return pre;
            }, []);
          case 'award':
            if (this.vipAwardList.length === 0) {
              return null;
            }
            return this.vipAwardList;
          default:
            return null;
        }
      } catch (err) {
        return null;
      }
    },
  },

  watch: {
    vipRecommendInfo(n) {
      this.mgmRecommendGoogleUrl += '?rc=' + n.recommend_code;
    },
  },

  created() {
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },

  mounted(){
    this.onSearch('firstPage');
  },

  methods: {
    copyUrl() {
      const copyText = document.getElementById('copyTextId');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
    onChange(event){
      this.dateType = event;
      if (event !== vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE){
        this.onSearch('firstPage');
      }
    },
    async onSearch(type) {
      // TODO: 待頁面頁面切換時可能需要加上
      if (type === 'firstPage') {
        this.next_currentPage = 1;
      }

      if (this.dateType === vipMgmConstants.DATE_TYPE.CUSTOM_SET_DATE) { // 如果是使用者自訂的話
        if (this.recordListParams.cts === '' || this.recordListParams.cte === '') {
          return;
        }
      }

      if (this.recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND) { // 推薦紀錄查詢
        const response = await lioshutanApi.vipMgm.getVipRecommendList({
          ac: vipMgmConstants.API_PARAM.AC.RECORD,
          page: this.next_currentPage,
          ...this.dateRange,
        });
        console.log(response.data.data.mgm_lists);
        this.vipRecommendList = response.data.data.mgm_lists;
      } else if (this.recordType === vipMgmConstants.RECORD_TYPE.TICKET) { // 抽獎券紀錄查詢
        try {
          const response = await lioshutanApi.vipMgm.getVipTicketList({
            ac: vipMgmConstants.API_PARAM.AC.TICKET,
            page: this.next_currentPage,
            ...this.dateRange,
          });

          if (response.data.data.mgm_ticket_lists.length <= 5) {
            this.clickNumber = [true, true, true, true, true];
          } else {
            this.clickNumber = [];
          }
          // this.ticketList = response;
          this.vipTicketList = response.data.data.mgm_ticket_lists;
        } catch (error) {
          console.log(error);
        }
      } else if (this.recordType === vipMgmConstants.RECORD_TYPE.AWARD) { // 獎品紀錄查詢
        const response = await lioshutanApi.vipMgm.getVipAwardList({
          ac: vipMgmConstants.API_PARAM.AC.RECORD,
          page: this.next_currentPage,
        });
        // this.awardList = response;
        this.vipAwardList = response.data.data.mgm_prize_lists;
      }
    },
    recommendVipStatus(vip){
      // TODO: 待後續與後端討論是否由後端傳該參數
      if (vip.bd_status === 'new' || vip.bd_status === 'bonded' || vip.bd_status === 'contact') {
        return '聯絡中';
      } else if (vip.bd_status === 'demo') {
        return '體驗中';
      } else if (vip.bd_status === 'ordered') {
        return '訂單建立';
      } else if (vip.bd_status === 'closed' || vip.bd_status === 'unbonded') {
        return '結案(' + vip.bd_close_reason_name + ')';
      } else if (vip.bd_status === 'purchased') {
        if (vip.order_data === null || vip.order_data.length === 0){
          return '已購買(無訂單)';
        } if (vip.order_data[0].status === 'purchased') {
          return '已購買(已開通)';
        } else if (vip.order_data[0].status === 'paid') {
          return '已購買(已付款)';
        } else if (vip.order_data[0].status === 'refunded') {
          return '已購買(退款)';
        } else {
          return '已購買(無訂單)';
        }
      } else if (vip.bd_status === 'refunded') {
        return '退款';
      }
    },
    async changeType(value){
      this.recordType = await value;
      if (this.recordType === vipMgmConstants.RECORD_TYPE.RECOMMEND) { // 推薦紀錄查詢
        this.dateType = vipMgmConstants.DATE_TYPE.TODAY;
      } else if (this.recordType === vipMgmConstants.RECORD_TYPE.TICKET) { // 抽獎券紀錄查詢
        this.dateType = vipMgmConstants.DATE_TYPE.THIS_MONTH;
      }
      this.onChange(this.dateType);
    },
    awardType(type){
      switch (type) {
        case 'raffle':
          return '抽獎獎品';
        case 'activity':
          return '抽獎獎品';
        case 'deal':
          return '成交獎品';
        case 'deal_bonus':
          return '成交獎金';
        default:
          return '資料錯誤';
      }
      // if (type === 'raffle' || type === 'activity') {
      //   return '抽獎獎品';
      // } else if (type === 'deal') {
      //   return '成交獎品';
      // } else {
      //   return '資料錯誤';
      // }
    },
    ticketStatus(ticket){
      if (ticket.status === 'auditing'){
        return '審核中';
      } else if (ticket.status === 'approved'){
        if (ticket.won === 0 && ticket.draw === 0){
          return '未開獎';
        } if (ticket.won === 0 && ticket.draw === 1){
          return '未中獎';
        } if (ticket.won === 1 && ticket.draw === 0){
          return '異常';
        } if (ticket.won === 1 && ticket.draw === 1){
          return '中獎未領取';
        }
      } else if (ticket.status === 'redeemed'){
        if (ticket.won === 1 && ticket.draw === 0){
          return '已領獎';
        } else {
          return '資料異常';
        }
      } else if (ticket.status === 'invalid'){
        return '無效';
      }
    },
    awardTypeValue(data){
      switch (data.type) {
        case 'raffle':
          return '抽獎序號<br>' + data.tickets_details.code;
        case 'activity':
          return '抽獎序號<br>' + data.tickets_details.code;
        case 'deal':
          return '<span style="line-height: 40px">' + data.prizes_config.rule_name + '</span>';
        case 'deal_bonus':
          return '<span style="line-height: 40px">' + data.prizes_config.rule_name + '</span>';
        default:
          return '資料錯誤';
      }
      // if (data.type === 'deal') {
      //   return '<span style="line-height: 40px">' + data.prizes_config.rule_name + '</span>';
      // } else if (data.type === 'raffle' || data.type === 'activity'){
      //   return '抽獎序號<br>' + data.tickets_details.code;
      // } else {
      //   return '資料錯誤';
      // }
    },
    awardStatus(status){
      if (status === 'not_received') {
        return '未領取';
      } else if (status === 'received') {
        return '領取';
      } else if (status === 'invalid') {
        return '無效';
      } else if (status === 'disqualified') {
        return '資格不符';
      } else {
        return '資料錯誤';
      }
    },
    changeMomentFormat(time, type = 'YYYY-MM-DD HH:mm:ss') {
      return moment(time).format(type);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 475px) {
      @content;
  }
}
@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}
@mixin xxl {
    @media (min-width: 1680px) {
      @content;
    }
}
@mixin xxxl {
  @media (min-width: 1920px) {
      @content;
  }
}
.rewards-table {
  margin-top: 20px;
  padding: 0 12px;
  @include lg {
    padding: 0;
    border-bottom: 1px solid #B0B0B0;
  }
  .reward-detail:not(:last-child) {
    margin-bottom: 20px;
  }
}
.reward-detail-header {
  border-top: 1px solid #B0B0B0;
  background: #F4F1EE;
  padding: 8px 0;
  display: none;
  color: #464B5D;
  @include lg {
    display: flex;
    align-items: center;
  }
  &.award {
    p {
      width: 20%;
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }
  &.ticket, &.recommend {
    p {
      width: 25%;
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }
}
.reward-detail {
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(187, 198, 228, 0.30);
  @include lg {
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  &.award div {
    @include lg {
      width: 20%;
    }
  }
  &.ticket div, &.recommend div {
    @include lg {
      width: 25%;
    }
  }
}
.reward-detail-item {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  @include lg {
    justify-content: center;
    padding: 12px 0;
  }
  h4 {
    color: #9E9E9E;
    min-width: 90px;
    font-size: 14px;
    margin: 0;
    margin-right: 20px;
    @include lg {
      display: none;
    }
  }
  p {
    margin: 0;
    padding: 0;
    color: #464B5D;
    font-size: 14px;
    @include lg {
      text-align: center;
    }
  }
}
.exclamation-icon {
    border-radius: 50%;
    padding: 1px 6px;
    background: lighten(#4dbd74, 20%);
    margin-left: 4px;
}
.popper-customer {
    position: relative;
    border: none;
    outline: none;
    &-content {
        background: #fff;
        box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
        color: #F0A547;
        display: none;
        z-index: 10;
        padding: 6px 12px;
        border-radius: 8px;
        position: absolute;
        font-size: 15px;
        top: 0;
        left: 50%;
        transform: translate(-50%, -117%);
    }
    &:focus-within .popper-customer-content {
        display: inline-block;
    }
    &:hover .popper-customer-content {
        display: inline-block;
    }
}
.google-groupReport {
  border-radius: 10px;
  border: 1px solid #216DDB;
  padding: 20px;
  text-align: center;
  img {
    display: inline-block;
    aspect-ratio: 232/74;
    width: 100%;
    max-width: 232px;
  }
  .copyText {
    outline: none;
    appearance: none;
    border: 1px solid #216DDB;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 20px;
    + .text-right button {
      background: #216DDB;
      color: #fff;
    }
  }
}
</style>
