var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "justify-content-md-center",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
      "data-aos-delay": "1000",
      "data-aos-once": "true"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("推薦四步驟")])]), _vm._l(_vm.stepGroup, function (step, index) {
    return _c('b-col', {
      key: `stepGroup_${index}`,
      attrs: {
        "col": "",
        "lg": "3"
      }
    }, [_c('img', {
      staticClass: "stepImg",
      attrs: {
        "fluid": "",
        "src": step.imgUrl,
        "alt": step.title,
        "title": step.title
      }
    }), _c('p', {
      staticClass: "text-center step-text",
      domProps: {
        "innerHTML": _vm._s(step.content)
      }
    })]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }